(function () {
    'use strict';
}());

app.service('UtilityService', [
    '$rootScope',
    function ($rootScope) {
        var self = this;

        self.getSitePhone = function () {
            return $rootScope.phone;
        };

        self.isValidEmail = function (email) {
            return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));
        };

        self.cleanCsv = function (input) {
            return input ? input.toString().replace(/"/g, '""') : input;
        };
    }
]);
