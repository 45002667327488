(function () {
    'use strict';
}());

app.controller('ShoppingListsController', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    'ShoppingListService',
    'NgTableParams',
    'UserService',
    'FileSaver',
    'Blob',
    'lists',
    function ($rootScope, $scope, $state, $uibModal, ShoppingListService, NgTableParams, UserService, FileSaver, Blob, lists) {
        $rootScope.disablePaddedContent = true;
        $scope.setState($state.current.name);
        $scope.loading = false;
        $scope.updatingListLoader = false;
        $scope.lists = lists;
        $scope.tableParams = new NgTableParams({},{
            dataset: $scope.lists
        });

        $scope.$on('refreshLists', function (e, a) {
           $scope.tableParams = new NgTableParams({}, {
                dataset: a
           }) ;
        });

        $scope.deleteList = function(list){
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'deleteListModal.html',
                controller: 'deleteListModalInstanceCtrl',
                windowClass: '',
                size: 'md',
                resolve: {
                    list: function(){
                        return list;
                    },
                    user: function(){
                        return $scope.user;
                    }
                }
            });

            modalInstance.result.then(function () {
                ShoppingListService.getLists().then(function (data) {
                    $scope.lists = data;
                    $scope.$broadcast('refreshLists', $scope.lists);
                });
            }, function() {
                // modal dismissed
            });
        };

        $scope.addList = function() {
            var options = {
                promptModal: false,
                createListModal: true,
                closePrompt: true,
                list: {}
            };

            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'shoppingListModal.html',
                controller: 'shoppingListModalInstanceCtrl',
                windowClass: 'shopping-list-modal',
                size: 'lg',
                resolve: {
                    msg: function () {
                        return null;
                    },
                    lists: function(){
                        return null;
                    },
                    selectedItems: function(){
                        return null;
                    },
                    options: function () {
                        return options;
                    }
                }
            });

            modalInstance.result.then(function () {
                ShoppingListService.getLists().then(function (data) {
                    $scope.lists = data;
                    $scope.$broadcast('refreshLists', $scope.lists);
                });
            }, function() {
                // modal dismissed
            });
        };

        $scope.canExportLists = $scope.lists && $scope.lists.length && UserService.isAdmin($scope.user);

        $scope.exportLists = function () {
            var exportData = ShoppingListService.shoppingListExport($scope.user, $scope.lists);
            if (exportData) {
                var file = new Blob(exportData, {type: 'text/csv;charset=utf-8'});
                FileSaver.saveAs(file, 'shopping-lists-'+$scope.user.customer_number+'.csv');
            } else {
                $rootScope.$broadcast('notify', {message: "Unable to export at this time."});
            }
        };
    }
]);

app.controller('ShoppingListDetailController', [
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    '$uibModal',
    '$filter',
    'AlgoliaService',
    'CartService',
    'ShoppingListService',
    'UserService',
    'list',
    'lodash',
    'FileSaver',
    'Blob',
    'NgTableParams',
    'BLACKLIST_FACETS',
    function ($rootScope, $scope, $state, $timeout, $uibModal, $filter, AlgoliaService, CartService, ShoppingListService, UserService, list, lodash, FileSaver, Blob, NgTableParams, BLACKLIST_FACETS) {

        if (!list) {
            $state.go('account.shopping_lists');
            $rootScope.$broadcast('notify', {message: "Unable to get shopping list details"});
        }

        list.edit = 0;
        $scope.setState('account.shopping_lists');

        $rootScope.setMetaTitle("Shopping List - " + list.name);

        $scope.updateList = function (list) {
            $scope.$broadcast('updateList', list);
        };

        $scope.cancel = function () {
            list.name = $scope.originalList.name;
            list.description = $scope.originalList.description;
            list.public = Boolean($scope.originalList.public);
            list.edit = 0;
        };

        $scope.meta = {
            limit: 10,
            page: 1,
            search: '',
            tags: []
        };
        $scope.tagFilter = {selected: null};

        $scope.$on('updateList', function (e, a) {
            a.edit = 0;
            if ($scope.list !== undefined) {
                $scope.list = {};
            }

            $scope.list = angular.copy(a);
            $scope.$broadcast('updateItems', $scope.list);
            $scope.items = $scope.meta.search ? $filter('filter')(a.items, $scope.meta.search) : a.items;
            if ($scope.meta.tags && $scope.meta.tags.length) {
                $scope.items = $filter('filter')($scope.items, function (item) {
                    return !item.tags ? false : $scope.meta.tags.some(function (tags) {
                        return item.tags.indexOf(tags) !== -1;
                    });
                });
            }
            $scope.tableParams = new NgTableParams({
                page: $scope.meta.page,
                count: $scope.meta.limit
            }, {
                dataset: $scope.items
            });
        });

        $scope.$on('updateItems', function (e, a) {
            if (!a.items || a.items.length === 0) {
                $scope.list = angular.copy(a);
                return false;
            }

            a.tags = [];
            angular.forEach(a.items, function (item) {
                item.displayItemNo = $filter('partNumber')(item, $rootScope.currentCustomer);
                if (item.tags) {
                    a.tags = lodash.union(a.tags, item.tags);
                }

                if (!item.hasOwnProperty("partNumber")) {
                    item.partNumber = item.erp;
                }

                var continueParse = false;
                angular.forEach(item.specifications, function (spec) {
                    if (!spec.hasOwnProperty("title")) {
                        continueParse = true;
                    }
                });

                if (continueParse) {
                    AlgoliaService.searchProducts({term: item.partNumber}).then(function (data) {
                        if (data && data.hits.length > 0) {
                            var hits = data.hits;
                            var product;
                            if (hits.length === 1) {
                                product = hits[0];
                            } else {
                                angular.forEach(hits, function (hit) {
                                    angular.forEach(hit.items, function (i) {
                                        if (i.erp === item.erp) {
                                            product = hit;
                                        }
                                    });
                                });
                            }

                            var specifications = [];
                            angular.forEach(item.specifications, function (ispec) {
                                var specs = {title: "", value: ispec};
                                specifications.push(specs);
                            });

                            angular.forEach(product.item_specifications, function (pspec) {
                                var s = pspec.split("|");
                                var specs = {title: s[0], value: s[1]};
                                if (!BLACKLIST_FACETS.includes(s[0])) {
                                    angular.forEach(specifications, function (spec) {
                                        if (spec.value === specs.value) {
                                            spec.title = specs.title;
                                        }
                                    });
                                }
                            });

                            item.specifications = specifications;
                        }
                    });
                }
            });
            a.tags = lodash.orderBy(lodash.sortedUniq(a.tags));
        });

        if (!list.items) {
            list.items = [];
        }

        $scope.$broadcast('updateItems', list);
        $scope.list = list;
        $scope.items = list.items;
        $scope.tableParams = new NgTableParams({
            page: $scope.meta.page,
            count: $scope.meta.limit
        }, {
            dataset: $scope.items
        });

        $scope.canEditList = $scope.user.id === $scope.list.uid || UserService.isAdmin($scope.user);
        $scope.canEditItems = $scope.user.id === $scope.list.uid;
        $scope.canShareList = function () {
            return $scope.canEditItems && !$scope.list.public && $scope.list.items && $scope.list.items.length > 0;
        };

        try {
            var isFileSaverSupported = !!new Blob;
        } catch (e) {}

        $scope.canExportList = function () {
            return $scope.list.items && $scope.list.items.length > 0 && isFileSaverSupported;
        };
        $scope.canAddItem = function (item) {
            return item.price && item.price > 0 && item.quantity && item.quantity > 0;
        };

        $scope.changeItemQty = function () {
            if ($scope.canEditItems) {
                ShoppingListService.updateList($scope.list).then(function () {
                    $rootScope.$broadcast('notify', {message: "Item saved"});
                });
            }
        };

        var check = 0;
        $scope.addQty = function (item) {
            item.quantity++;
            var timer = $timeout(function () {
                $scope.changeItemQty();
                check = 0;
            }, 2000);
            check++;
            if (check > 1) {
                $timeout.cancel(timer);
            }
        };

        $scope.reduceQty = function (item) {
            if (item.quantity >= 1) {
                item.quantity--;
                var timer = $timeout(function () {
                    $scope.changeItemQty();
                    check = 0;
                }, 2000);
                check++;
                if (check > 1) {
                    $timeout.cancel(timer);
                }
            }
        };

        $scope.removeItem = function (list, item) {
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'deleteListItemModal.html',
                controller: 'deleteListItemModalInstanceCtrl',
                windowClass: '',
                resolve: {
                    list: function () {
                        return list;
                    },
                    item: function () {
                        return item;
                    }
                }
            });

            modalInstance.result.then(function (list) {
                ShoppingListService.getList(list.id).then(function (data) {
                    $scope.$broadcast('updateList', data);
                });
            }, function () {
                // modal dismissed
            });
        };

        $scope.addItemToList = function (list) {
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'addItemToListModal.html',
                controller: 'addItemToListModalInstanceCtrl',
                windowClass: '',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    list: function () {
                        return list;
                    },
                    user: function () {
                        return $scope.user;
                    }
                }
            });

            modalInstance.result.then(function (list) {
                ShoppingListService.getList(list.id).then(function (data) {
                    $scope.$broadcast('updateList', data);
                });
            }, function () {
                // modal dismissed
            });
        };

        $scope.addItemToCart = function (item) {
            $rootScope.$broadcast('open-loading-modal');
            UserService.getSessionId().then(function (session) {
                CartService.postCart(session, [item]).then(function (data) {
                    $rootScope.$broadcast('close-loading-modal');
                    if (data) {
                        $rootScope.$broadcast('notify', {message: "Success! The item was added to your cart."});
                    } else {
                        $rootScope.$broadcast('notify', {message: "An error occurred. The item could not be added to the cart."});
                    }
                });
            });
        };

        $scope.addAllToCart = function () {
            if ($scope.items.length > 0) {
                var availableItems = $filter('filter')($scope.items, function (item) {
                    return $scope.canAddItem(item);
                });

                if (availableItems.length > 0) {
                    $rootScope.$broadcast('open-loading-modal');
                    UserService.getSessionId().then(function (session) {
                        CartService.postCart(session, availableItems).then(function (data) {
                            $rootScope.$broadcast('close-loading-modal');
                            if (data) {
                                $rootScope.$broadcast('notify', {message: "Success! The items are now in your cart."});
                            } else {
                                $rootScope.$broadcast('notify', {message: "An error occurred. Items could not be added to the cart."});
                            }
                        });
                    });
                } else {
                    $rootScope.$broadcast('notify', {message: "These items are unavailable and can not be added to your cart."});
                }
            }
        };

        $scope.editList = function (list) {
            $scope.originalList = angular.copy(list);
            list.edit = 1;
            return list;
        };

        $scope.saveList = function (list) {
            $rootScope.$broadcast('open-loading-modal');
            list.edit = 2;
            ShoppingListService.updateList(list).then(function () {
                list.edit = 0;
                $rootScope.$broadcast('close-loading-modal');
                return list;
            });
        };

        $scope.selectedItems = [];
        $scope.selectItemForCart = function (item) {
            if ($scope.itemChecked(item)) {
                $scope.selectedItems.splice($scope.selectedItems.indexOf(item), 1);
            } else {
                $scope.selectedItems.push(item);
            }
        };

        $scope.itemChecked = function (item) {
            var selected = $filter('filter')($scope.selectedItems, {'erp': item.erp});
            return selected.length;
        };

        $scope.addSelectedToCart = function () {
            $rootScope.$broadcast('open-loading-modal');
            UserService.getSessionId().then(function (session) {
                CartService.postCart(session, $scope.selectedItems).then(function (data) {
                    $rootScope.$broadcast('close-loading-modal');
                    if (data) {
                        $rootScope.$broadcast('notify', {message: "Success! The items are now in your cart."});
                    } else {
                        $rootScope.$broadcast('notify', {message: "An error occurred. Items could not be added to the cart."});
                    }
                }).finally(function () {
                    $scope.selectedItems = [];
                });
            });
        };

        $scope.shareList = function (list) {
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: "shareListModal.html",
                controller: "ShareListModalInstanceController",
                windowClass: '',
                size: 'lg',
                resolve: {
                    list: function () {
                        return list;
                    },
                    user: function () {
                        return $scope.user;
                    }
                }
            });

            modalInstance.result.then(function (list) {
                ShoppingListService.getList(list.id).then(function (data) {
                    $scope.$broadcast('updateList', data);
                });
            }, function () {
                // modal dismissed
            });
        };

        $scope.exportList = function (list) {
            var exportData = ShoppingListService.shoppingListExport($scope.user, [list]);
            if (exportData) {
                var file = new Blob(exportData, {type: 'text/csv;charset=utf-8'});
                FileSaver.saveAs(file, 'shopping-list-'+list.id+'.csv');
            } else {
                $rootScope.$broadcast('notify', {message: "Unable to export at this time."});
            }
        };

        $scope.addTagFilter = function (tag) {
            if (tag && $scope.meta.tags.indexOf(tag) === -1) {
                $scope.meta.tags.push(tag);
                $scope.$broadcast('updateList', $scope.list);
            }
            $scope.tagFilter.selected = null;
        };

        $scope.removeTagFilter = function (index) {
            $scope.meta.tags.splice(index, 1);
            $scope.$broadcast('updateList', $scope.list);
        };

        $scope.addItemTag = function (list, itemId) {
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'addItemTagModal.html',
                controller: 'addItemTagModalInstanceCtrl',
                windowClass: '',
                resolve: {
                    list: function () {
                        return list;
                    },
                    itemId: function () {
                        return itemId;
                    }
                }
            });

            modalInstance.result.then(function (list) {
                ShoppingListService.getList(list.id).then(function (data) {
                    $scope.$broadcast('updateList', data);
                });
            }, function () {
                // modal dismissed
            });
        };

        $scope.removeItemTag = function (itemId, index) {
            var item = $filter('filter')($scope.list.items, {_id: itemId}, true);
            if (item && item[0] && item[0].tags[index]) {
                if (confirm('Are you sure you want to delete this tag?')) {
                    item[0].tags.splice(index, 1);
                    ShoppingListService.updateList($scope.list).then(function (l) {
                        $scope.$broadcast('updateList', l);
                    });
                }
            } else {
                $rootScope.$broadcast('notify', {message: 'An error occurred while deleting the tag. Please try again.'});
            }
        };
    }
]);

app.controller('deleteListModalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    'ShoppingListService',
    'list',
    'user',
    function($rootScope, $scope, $uibModalInstance, ShoppingListService, list, user){
        $scope.list = list;

        $scope.deleteList = function(){
            if (list.uid === user.id) {
                // Delete list
                $rootScope.$broadcast('open-loading-modal');
                ShoppingListService.deleteList(list).then(function (data) {
                    $rootScope.$broadcast('close-loading-modal');
                    var msg = "";
                    if(data){
                        msg = 'Success! The shopping list was deleted.';
                        $scope.refresh();
                        $uibModalInstance.close();
                    } else {
                        msg = 'An error occurred while deleting the shopping list. Please try again.';
                        $scope.cancel();
                    }
                    $rootScope.$broadcast('notify', {message: msg});
                });
            } else if (list.users && list.users.indexOf(user.id) >= 0) {
                // Opt out of shared list
                $rootScope.$broadcast('open-loading-modal');
                list.users = list.users.filter(function(value){
                    return value !== user.id;
                });

                ShoppingListService.updateList(list).then(function () {
                    $rootScope.$broadcast('close-loading-modal');
                    $scope.refresh();
                    $uibModalInstance.close();
                    $rootScope.$broadcast('notify', {message: 'Success! The shopping list was deleted.'});
                });
            } else {
                // This should never happen
                $scope.cancel();
                $rootScope.$broadcast('notify', {message: 'An error occurred while deleting the shopping list. Please try again.'});
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.refresh = function () {
            ShoppingListService.getLists().then(function (data) {
                $scope.$broadcast('refreshLists', data);
            });
        }
    }
]);

app.controller('deleteListItemModalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    'ShoppingListService',
    'list',
    'item',
    function($rootScope, $scope, $uibModalInstance, ShoppingListService, list, item){
        $scope.list = list;
        $scope.item = item;
        $scope.removeItem = function(){
            $rootScope.$broadcast('open-loading-modal');
            ShoppingListService.deleteListItem(list, item).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                var msg = "";
                if (data){
                    msg = "Success! The item was deleted.";
                    data.edit = 0;
                    $uibModalInstance.close(data);
                } else {
                    msg = "An error occurred while deleting the item. Please try again.";
                    $scope.cancel();
                }
                $rootScope.$broadcast('notify', {message: msg});
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
]);

app.controller('addItemTagModalInstanceCtrl', [
    '$filter',
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    'ShoppingListService',
    'list',
    'itemId',
    function($filter, $rootScope, $scope, $uibModalInstance, ShoppingListService, list, itemId){
        $scope.list = list;
        $scope.tag = '';

        $scope.saveTag = function(){
            $scope.tag = $scope.tag.trim();
            if ($scope.tag) {
                if ($scope.list.tags) {
                    // make sure we use existing tag if there are only case differences
                    angular.forEach($scope.list.tags, function (tag) {
                        if ($scope.tag.toLowerCase() === tag.toLowerCase()) {
                            $scope.tag = tag;
                        }
                    });
                }

                var item = $filter('filter')($scope.list.items, {_id: itemId}, true);
                if (!item || !item[0]) {
                    $scope.cancel();
                    $rootScope.$broadcast('notify', {message: 'An error occurred while updating the item. Please try again.'});
                    return false;
                }

                if (!item[0].tags || item[0].tags.indexOf($scope.tag) === -1) {
                    if (!item[0].tags) { item[0].tags = []; }
                    item[0].tags.push($scope.tag);

                    $rootScope.$broadcast('open-loading-modal');
                    ShoppingListService.updateList($scope.list).then(function (data) {
                        var msg = "";
                        if (data){
                            msg = "Success! The tag was added.";
                            $uibModalInstance.close(data);
                        } else {
                            msg = "An error occurred while updating the item. Please try again.";
                            $scope.cancel();
                        }
                        $rootScope.$broadcast('notify', {message: msg});
                    }).finally(function () {
                        $rootScope.$broadcast('close-loading-modal');
                    });

                } else {
                    $scope.cancel();
                    $rootScope.$broadcast('notify', {message: $scope.tag + ' already exists on this item'});
                }
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
]);

app.controller('addItemToListModalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    '$window',
    '$filter',
    'AlgoliaService',
    'PricingService',
    'ShoppingListService',
    'BLACKLIST_FACETS',
    'list',
    'user',
    function ($rootScope, $scope, $uibModalInstance, $window, $filter, AlgoliaService, PricingService, ShoppingListService, BLACKLIST_FACETS, list, user) {
        var searchReset = function () {
            document.getElementById('shoppingListItemSearch').focus();
            document.getElementById('shoppingListItemSearch').value = "";
        };
        $scope.list = list;
        setTimeout(function () { searchReset(); }, 500);

        $scope.loading = false;
        $scope.adding = false;
        $scope.searchBy = 'Search by part number or keywords';
        $scope.$on('foundProducts', function (e, a) {
            $scope.loading = false;
            $scope.results = Object.keys(a).map(function (key) {
                return a[key];
            });

            angular.forEach($scope.results, function (result) {
               angular.forEach(result.items, function (item) {
                   PricingService.getPricing([item]).then( function (pricing) {
                       if (pricing) {
                           pricing = pricing[0][0];
                           item.price = pricing.price;
                           item.quantity_available = pricing.quantity_available;
                           item.uom = pricing.uom;
                       }

                       item.quantity = 0;
                   });

                   if (!item.hasOwnProperty("units")) {
                       angular.forEach(item.item_specifications, function (spec) {
                           var split = spec.split('|');
                           if (split[0] === "Units") {
                               item.units = Number(split[1]);
                           }
                       });
                   }
               });
            });

            if ($scope.searchTerm === "") $scope.results = {};
        });

        // Search ERPs to add to shopping list
        $scope.search = function (term) {
            $scope.loading = true;
            var results = {};
            var params ={
                term: term,
                hitsPerPage: 100,
                facets: "*"
            };

            AlgoliaService.searchProducts(params).then( function (data) {
                if (data.hits.length > 0) {
                    //loop each hit
                    angular.forEach(data.hits, function (hit) {
                        //loop each item in hit
                        angular.forEach(hit.items, function (item) {
                            item.displayItemNo = $filter('partNumber')(item, $rootScope.currentCustomer)
                            item.partNumber = item.erp;
                        });
                        results[hit.objectID] = hit;
                    });
                    $scope.$broadcast('foundProducts', results);
                } else {
                    $scope.$broadcast('foundProducts', results);
                }
            });
        };

        $scope.addQty = function (item) {
            item.quantity++;
        };

        $scope.reduceQty = function (item) {
            if (item.quantity >= 1) {
                item.quantity--;
            }
        };

        $scope.addItemToList = function (i, p) {
            var specifications = [], filter = {};
            var item = angular.copy(i),
                product = angular.copy(p);
            var itemFound = false;
            $scope.adding = item.displayItemNo;
            angular.forEach(product.item_specifications, function (pspec) {
                var s = pspec.split("|");
                if (!BLACKLIST_FACETS.includes(s[0])) {
                    filter[s[0]] = s[1];
                }
            });

            angular.forEach(item.specifications, function (ispec) {
                angular.forEach(filter, function (v,k) {
                    if (filter[k] === ispec) {
                        specifications.push({title: k, value: v});
                    }
                });
            });

            item.specifications = specifications;
            item.taxonomy = product.taxonomy;
            item.product_number = product.product_number;
            item.product_id = product.product_id;
            item.image = product.image;
            item.vendor = product.vendor.slug;

            angular.forEach($scope.list.items,function(row) {
                if (row.erp === item.erp ) {
                    row.quantity = row.quantity + item.quantity;
                    itemFound = true;
                }
            });
            if (!itemFound) {
                $scope.list.items.push(item);
            }
            ShoppingListService.updateList($scope.list).then(function (data) {
                $scope.list = data;
                $rootScope.$broadcast('notify', {message: "Item added to " + $scope.list.name});
            }).catch(function () {
                $rootScope.$broadcast('notify', {message: "An error occurred. The item could not be added to the cart."});
            }).finally(function (){
                $scope.adding = false;
                searchReset();
            });
        };

        $scope.done = function () {
            $uibModalInstance.close($scope.list);
        }
    }
]);

app.controller('ShareListModalInstanceController', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    'ShoppingListService',
    'UserService',
    'list',
    'user',
    function ($rootScope, $scope, $window, $uibModalInstance, ShoppingListService, UserService, list, user) {
        $scope.loading = false;
        $scope.closeModal = function () {
            $uibModalInstance.close(list);
        };

        $scope.isAdmin = UserService.isAdmin(user);
        $scope.list = list;
        $scope.selected = [];

        $scope.$on('getUsers', function (e,a) {
            $scope.loading = true;
            $scope.users = [];

            UserService.getUsersByCustomerNumber(user.customer_number, 'user.id,user.username,user.name').then(function (data) {
                if (data) {
                    angular.forEach(data, function (u) {
                        if (u.id !== user.id) {
                            $scope.users.push(u);
                            if (angular.isArray(list.users) && list.users.indexOf(u.id) >= 0) {
                                $scope.selected.push(u.id);
                            }
                        }
                    });
                }
                $scope.loading = false;
            }).catch( function (err) {
                console.error(err);
            });
        });

        $scope.tableLoaded = function() {
            setTimeout(function() {
                $('#userList').removeClass('hide').DataTable({
                    "aoColumns": [
                        null,
                        null,
                        { "bSortable": false }
                    ]
                })
            }, 0);
        };

        $scope.$broadcast('getUsers');

        $scope.toggleUser = function (u) {
            if ($scope.isSelected(u)) {
                var index = $scope.selected.indexOf(u.id);
                $scope.selected.splice(index, 1);
            } else {
                $scope.selected.push(u.id);
            }
        };

        $scope.getUsers = function () {
            $scope.$broadcast('getUsers');
        };

        $scope.isSelected = function (user) {
            return $scope.selected.indexOf(user.id) > -1;
        };

        $scope.submitShareList = function () {
            ShoppingListService.shareList(list, $scope.selected)
                .then( function (data) {
                    $rootScope.$broadcast('notify', { message: (data) ? "Shopping List recipients have been updated." : "Unable to share Shopping List. Please contact support." });
                    $scope.closeModal();
                }).catch( function(err) {
                    console.error(err);
                });
        };

        $scope.deselectUsers = function () {
            $scope.selected = [];
        };
    }
]);
