(function () {
    'use strict';
}());

app.service('AccountService', [
    '$rootScope',
    '$sce',
    '$filter',
    'DOMAIN',
    'CompanyService',
    'UserService',
    function ($rootScope, $sce, $filter, DOMAIN, CompanyService, UserService) {
        var self = this;

        self.getAccountMenu = function (user, workflowType) {
            /**
             * Each account menu link can easily be rendered by the following
             * object structure:
             * {
             *  title: Title of link
             *  route: The nested route within the admin panel
             * }
             * @type {Array}
             */

            var isAdmin = user.roles.indexOf("ROLE_ADMIN") !== -1 || user.roles.indexOf("ROLE_SUPERADMIN") !== -1;
            var isSupervisor = user ? user.is_supervisor : false;
            var storeFrontAccounts = ['87023', '99762', '122185'];
            var notOpenBrowse = user ? storeFrontAccounts.indexOf(user.customer_number) === -1 : false;
            var hasSharedCarts = false;
            if (user.shared_carts && !Array.isArray(user.shared_carts)) {
                user.shared_carts = Object.values(user.shared_carts)
            }
            var sharedCarts = user.shared_carts ? user.shared_carts : [];
            if (sharedCarts && sharedCarts.length > 0) {
                hasSharedCarts = true;
            }
            var isPunchOut = UserService.getIsPunchOut();

            var accountMenu = {
                "accountManager": {
                    "id": 1,
                    "groupTitle": "Account Manager",
                    "routes": []
                },
                "ordersInvoices": {
                    "id": 2,
                    "groupTitle": "Orders, Quotes & Invoices",
                    "routes": []
                },
                "listsCartsApprovals": {
                    "id": 3,
                    "groupTitle": "Lists, Carts & Approvals",
                    "routes": []
                },
                "vallenTools": {
                    "id": 4,
                    "groupTitle": "Vallen Tools",
                    "routes": []
                }
            };

            /** ACCOUNT MANAGER **/

            if (UserService.canViewAccountOverview(user)) {
                accountMenu.accountManager.routes.push(
                    {
                        "title": "Overview",
                        "route": "account.summary"
                    }
                );
            }

            if (!isPunchOut) {
                accountMenu.accountManager.routes.push(
                    {
                        "title": "My Profile",
                        "route": "account.profile"
                    },
                    {
                        "title": "Shipping Addresses",
                        "route": "account.shipping"
                    }
                );
            }

            if (UserService.canViewBillingInfo(user)) {
                accountMenu.accountManager.routes.push(
                    {
                        "title": "Payment Info",
                        "route": "account.billing"
                    }
                );
            }

            if (UserService.canViewManageUsers(user)) {
                accountMenu.accountManager.routes.push(
                    {
                        "title": "Manage Users",
                        "route": "admin.users"
                    }
                );
            }

            if (UserService.canViewManageDepartments(user)) {
                accountMenu.accountManager.routes.push(
                    {
                        "title": "Manage Departments",
                        "route": "admin.departments"
                    }
                );
            }

            if (UserService.canViewUserSetup(user)) {
                accountMenu.accountManager.routes.push(
                    {
                        "title": "User Setup",
                        "route": "account.user_setup"
                    }
                );
            }

            if (UserService.canViewUniversalCrib(user)) {
                accountMenu.accountManager.routes.push(
                    {
                        "title": "Universal Crib",
                        "route": "account.universal_crib"
                    }
                );
            }

            if ('units' === workflowType && !isPunchOut) {
                accountMenu.accountManager.routes.push(
                    {
                        "title": "FRC Manager",
                        "route": "account.frc_manager"
                    }
                );
            }


            /** ORDERS & INVOICES **/

            if (UserService.canViewMyOrders(user)){
                accountMenu.ordersInvoices.routes.push(
                    {
                        "title": "My Orders",
                        "route": "account.orders"
                    }
                );
            }

            if (UserService.canViewQuoteRequest(user)) {
                accountMenu.ordersInvoices.routes.push(
                    {
                        "title": "Quote Request",
                        "route": "account.quote"
                    }
                );
            }

            if (UserService.canViewOrderNonStock(user)) {
                accountMenu.ordersInvoices.routes.push(
                    {
                        "title": "Order Non-Stock",
                        "route": "account.non_stock"
                    }
                );
            }

            if (UserService.canViewOrderHistory(user)) {
                accountMenu.ordersInvoices.routes.push(
                    {
                        "title": "Order History",
                        "route": "account.revieworders"
                    }
                );
            }

            if (UserService.canViewReviewQuotes(user)) {
                accountMenu.ordersInvoices.routes.push(
                    {
                        "title": "Review Quotes",
                        "route": "account.reviewquotes"
                    }
                );
            }

            if (UserService.canViewOpenInvoices(user)) {
                accountMenu.ordersInvoices.routes.push(
                    {
                        "title": "Open Invoices",
                        "route": "account.open_invoices"
                    }
                );
            }

            if (UserService.canViewPaidInvoices(user)) {
                accountMenu.ordersInvoices.routes.push(
                    {
                        "title": "Paid Invoices",
                        "route": "account.paid_invoices"
                    }
                );
            }

            /** LISTS, CARTS & APPROVALS **/
            accountMenu.listsCartsApprovals.routes = [
                {
                    "title": "Shopping Lists",
                    "route": "account.shopping_lists"
                }
            ];

            if (hasSharedCarts && !isPunchOut) {
                accountMenu.listsCartsApprovals.routes.push(
                    {
                        "title": "Shared Carts",
                        "route": "account.shared_carts"
                    }
                );
            }

            if (user.hasOwnProperty("scan_to_web_entries") && user.scan_to_web_entries.length) {
                accountMenu.listsCartsApprovals.routes.push(
                    {
                        "title": "Scan To Web carts",
                        "route": "account.scan_to_web"
                    }
                );
            }

            if ((isAdmin || isSupervisor) && !isPunchOut) {
                accountMenu.listsCartsApprovals.routes.push(
                    {
                        "title": "Order Approvals",
                        "route": "admin.order_requests"
                    }
                );
            }

            if ((isAdmin || isSupervisor) && 'units' === workflowType && !isPunchOut) {
                accountMenu.listsCartsApprovals.routes.push(
                    {
                        "title": "Profile Approvals",
                        "route": "admin.profile_requests"
                    }
                );
            }


            /** VALLEN TOOLS **/

            if (user.is_vallen) {
                accountMenu.vallenTools.routes = [
                    {
                        "title": "Customer Lookup",
                        "route": "vallen_tools.customers"
                    },
                    {
                        "title": "Vendor Lookup",
                        "route": "vallen_tools.vendors"
                    },
                    {
                        "title": "Product Lookup",
                        "route": "vallen_tools.products"
                    }
                ];
                if(user.manage_customer_apps){
                    accountMenu.vallenTools.routes.push(
                        {
                            "title": "Customer Applications",
                            "route": "vallen_tools.customer_applications"
                        }
                    );

                }
                accountMenu.vallenTools.routes.push(
                    {
                        "title": "Vallen.com Help Videos",
                        "route": "wordpress({path: 'helpvideos'})"
                    }
                )
            }
            return accountMenu;
        };
        self.initOrderFilter = function(scope) {
            // filter action buttons
            scope.applyFilters = function () {
                scope.getOrders(true);
            };

            scope.clearFilters = function () {
                scope.initFilters();
                scope.getOrders(true);
                scope.isFiltered = false;
            };

            scope.initFilters = function () {
                scope.orderFilter = {
                    customerNumber: scope.customerNumber,
                    selectedDate: scope.dateOptions && scope.dateOptions.length ? scope.dateOptions[0] : '',
                    fromDate: '',
                    toDate: '',
                    shipTo: '',
                    transType: scope.orderType && 'QU' === scope.orderType ? 'qu' : '',
                    filter: '',
                    viewType : scope.orderViewOptions[0].value,
                    omitCancelled: false
                };
            };

            scope.hasFilters = function () {
                return (scope.orderFilter.fromDate ||
                    scope.orderFilter.toDate ||
                    scope.orderFilter.shipTo ||
                    scope.orderFilter.filter ||
                    scope.orderFilter.omitCancelled ||
                    scope.orderFilter.viewType !== scope.orderViewOptions[0].value);
            };

            var typeLabel = scope.invoicePage ? 'Invoices' : scope.orderType && 'QU' === scope.orderType ? 'Quotes' : 'Orders';
            scope.orderViewOptions = [
                { value: 'mine', label: 'My ' + typeLabel }
            ];
            if (
                scope.invoicePage ||
                (scope.user.order_history && scope.state === 'account.revieworders') ||
                (UserService.canViewReviewQuotes(scope.user) && scope.state === 'account.reviewquotes')
            ) {
                var allOpt = {value: '', label: 'All ' + typeLabel};
                if (UserService.getIsPunchOut() && scope.state === 'account.reviewquotes') {
                    // punchout users only see 'All' option
                    scope.orderViewOptions = [allOpt];
                } else if (scope.state === 'account.revieworders' || scope.invoicePage) {
                    scope.orderViewOptions.unshift(allOpt);
                } else {
                    scope.orderViewOptions.push(allOpt);
                }
            }

            // initialize filters
            scope.initFilters();

            // date picker
            scope.datePickerFormat = 'MM/dd/yyyy';
            scope.datePicker = {
                from: {opened: false},
                to: {opened: false}
            };
            scope.openDatePicker = function (id) {
                scope.datePicker[id].opened = true;
            };

            // date options
            scope.dateOptions = [
                {value: '', label: 'All Entry Dates'},
                {value: '30', label: '30 days'},
                {value: '60', label: '60 days'},
                {value: '90', label: '90 days'},
                {value: 'custom', label: 'Entry Date Range'}
            ];
            scope.orderFilter.selectedDate = scope.dateOptions[0];

            scope.selectDateType = function () {
                if (scope.orderFilter.selectedDate.value == 'custom' || !scope.orderFilter.selectedDate.value) {
                    scope.orderFilter.fromDate = '';
                    scope.orderFilter.toDate = '';
                } else {
                    scope.orderFilter.fromDate = moment().subtract(scope.orderFilter.selectedDate.value, 'days').toDate();
                    scope.orderFilter.toDate = moment().toDate();
                }
            };

            scope.changeDates = function () {
                if (scope.orderFilter.fromDate || scope.orderFilter.toDate) {
                    scope.orderFilter.selectedDate = scope.dateOptions[scope.dateOptions.length - 1];
                } else if (!scope.orderFilter.fromDate && !scope.orderFilter.toDate) {
                    scope.orderFilter.selectedDate = scope.dateOptions[0];
                }
            };

            // ship to options
            scope.shipToOptions = [{
                value: '',
                label: 'All Ship To\'s'
            }];

            CompanyService.getCompanyShipTos(scope.customerNumber).then(function (data) {
                angular.forEach(data.ship_tos, function (shipTo) {
                    var addrLines = shipTo.addr.split(';');
                    shipTo.addrLines = addrLines.filter(function (x) {
                        return x !== '';
                    });
                    scope.shipToOptions.push({
                        value: shipTo.shipto,
                        label: shipTo.shipto + ': ' + shipTo.name + ' - ' + shipTo.addrLines[0] + '; ' + shipTo.city + ', ' + shipTo.state + ' ' + shipTo.zipcd
                    });
                });
            });
            scope.orderFilter.shipTo = scope.shipToOptions[0].value;
        };

        self.initItemDetailModal = function(scope, uibModal) {

            scope.itemDetailModal = function (orderLine) {
                scope.detailLine = orderLine;
                scope.fileGroup = [];
                if (scope.detailLine.webData.files) {
                    angular.forEach(scope.detailLine.webData.files, function(file) {
                        var fileType = file.split('.').pop();
                        var fileObj = {};
                        fileObj.isImage = (fileType == 'png' || fileType == 'jpg'|| fileType == 'jpeg' || fileType == 'gif');
                        fileObj.filePath = $rootScope.s3Resources + '/quote_nonstock/' + file;
                        fileObj.fileType = fileType;
                        scope.fileGroup.push(fileObj);
                    });
                }
                scope.currencyDisplay = orderLine.webData && orderLine.webData.units ? 'units' : 'dollars';

                scope.itemDetailModalInstance = uibModal.open({
                    templateUrl: 'itemDetailModal.html',
                    scope: scope
                });
                scope.closeLineDetails = function () {
                    scope.itemDetailModalInstance.close()
                };
                scope.itemDetailModalInstance.result.then(function(){
                    // modal closed
                }, function() {
                    // modal dismissed
                });
            };
        };

        self.initPasswordValidator = function (scope) {
            scope.passwordTooltip = null;
            scope.passwordKeyUp = function($event) {
                var password = null;
                if ($event) {
                    password = $event.target.value;
                }

                var tooltip = "Passwords must:";

                var requirements = {
                    "Be at least 6 characters long.": password && password.length >= 6,
                    "Include a letter.": password && password.match(/((?=.*[a-z]))/),
                    "Include a number.": password && password.match(/((?=.*\d))/)
                };

                var score = password ? password.length*2 : 0;
                angular.forEach(requirements, function(v, k) {
                    var spanStyle = '';
                    if (v) {
                        spanStyle = 'color:gray; text-decoration:line-through;';
                    }
                    tooltip += '<br><span style="' + spanStyle + '">' + k + '</span>';
                    if (v) {
                        score += 2;
                    }
                });

                if (password && password.match(/((?=.*[A-Z])(?=.*[a-z]))/)) {
                    score += 6;
                }

                if (password && password.match(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)) {
                    score += 8;
                }

                tooltip += '<br>Password Strength: ';
                if (score >= 40) {
                    tooltip += '<span style="color:green;">Strong</span>';
                } else if (score >= 30) {
                    tooltip += '<span style="color:yellow;">Moderate</span>';
                } else {
                    tooltip += '<span style="color:red;">Weak</span>';
                }

                scope.passwordTooltip = $sce.trustAsHtml('<div>' + tooltip + '</div>');
            };
        };

        self.formatInvAmt = function (order) {
            if(order.transtype === 'rm'){
                return order.totinvamt ? ('-' + $filter('currency')(order.totinvamt)) : '$0.00';
            }
            return order.totinvamt > 0 ? ($filter('currency')(order.totinvamt) ) : 'TBD';
        };
    }
]);
