app.service('ResourcesService',function(){
    var self =  this;

    self.getW9Downloads = function() {
        var baseUrl = '/assets/data/w9-downloads/';

        return [
            {
                label: 'Vallen Distribution Inc. W-9',
                url: baseUrl + 'VALLEN_DIST_INC_W9_2022.pdf'
            },
            {
                label: 'Vallen (dba Boring-Smith, Taylor Scale)',
                url: baseUrl + 'VALLEN_Specialty_Business_W9_2022.pdf'
            }
        ];
    };

    self.getResaleExemptionCertLinks = function() {
        var certs = [
            {
                title: 'Multijurisdiction',
                links : [
                    'Resale_Multijurisdiction.pdf'
                ]
            },
            {
                title: 'Alabama (AL)',
                links: [
                    'Resale_AL.pdf'
                ]
            },
            {
                title: 'Arkansas (AR)',
                links: [
                    'Resale_AR.pdf'
                ]

            },
            {
                title: 'Arizona (AZ)',
                links: [
                    'Resale_AZ.pdf'
                ]
            },
            {
                title: 'California (CA)',
                links: [
                    'Resale_CA.pdf',
                    'Resale_CA_590_Exemption.pdf'
                ]
            },
            {
                title: 'Colorado (CO)',
                links: [
                    'Resale_CO.pdf'
                ]
            },
            {
                title: 'Connecticut (CT)',
                links: [
                    'Resale_CT.pdf'
                ]
            },
            {
                title: 'District of Columbia (DC)',
                links: [
                    'Resale_DC.pdf'
                ]
            },
            {
                title: 'Florida (FL)',
                links: [
                    'Resale_FL_Corporate_0.pdf',
                    'Resale_FL_Corporate_5.pdf',
                    'Resale_FL_Clearwater-Hwy-19-N.pdf',
                    'Resale_FL_Clearwater-49th-ST-N.pdf',
                    'Resale_FL_Jacksonville.pdf',
                    'Resale_FL_Kissimmee.pdf',
                    'Resale_FL_Lakeland.pdf',
                    'Resale_FL_Merritt-Island.pdf',
                    'Resale_FL_Panama-City.pdf',
                    'Resale_FL_Riverview.pdf',
                    'Resale_FL_Vero-Beach.pdf'
                ]
            },
            {
                title: 'Georgia (GA)',
                links: [
                    'Resale_GA.pdf'
                ]
            },
            {
                title: 'Hawaii (HI)',
                links: [
                    'Resale_HI.pdf'
                ]
            },
            {
                title: 'Iowa (IA)',
                links: [
                    'Resale_IA.pdf'
                ]
            },
            {
                title: 'Idaho (ID)',
                links: [
                    'Resale_ID.pdf'
                ]
            },
            {
                title: 'Illinois (IL)',
                links: [
                    'Resale_IL.pdf'
                ]
            },
            {
                title: 'Indiana (IN)',
                links: [
                    'Resale_IN.pdf'
                ]
            },
            {
                title: 'Kansas (KS)',
                links: [
                    'Resale_KS.pdf'
                ]
            },
            {
                title: 'Kentucky (KY)',
                links: [
                    'Resale_KY.pdf'
                ]
            },
            {
                title: 'Louisiana (LA)',
                links: [
                    'Resale_LA_Convent.pdf',
                    'Resale_LA_Corporate.pdf',
                    'Resale_LA_Darrow.pdf',
                    'Resale_LA_Deridder.pdf',
                    'Resale_LA_Garyville.pdf',
                    'Resale_LA_Geismar-Ashland-Rd.pdf',
                    'Resale_LA_Geismar-Gateway-Dr.pdf',
                    'Resale_LA_Hahnville.pdf',
                    'Resale_LA_La-Place.pdf',
                    'Resale_LA_Metairie.pdf',
                    'Resale_LA_Saint-James.pdf',
                    'Resale_LA_Sulphur.pdf',
                    'Resale_LA_Westlake.pdf',
                    'Resale_LA_Geismar-Hwy-73.pdf',
                    'Resale_LA_Geismar-Hwy-75.pdf',
                    'Resale_LA_St-John-the-Baptist-Parish.pdf',
                    'Resale_LA_Thibodaux.pdf'
                ]
            },
            {
                title: 'Massachusetts (MA)',
                links: [
                    'Resale_MA.pdf'
                ]
            },
            {
                title: 'Maryland (MD)',
                links: [
                    'Resale_MD.pdf'
                ]
            },
            {
                title: 'Maine (ME)',
                links: [
                    'Resale_ME.pdf'
                ]
            },
            {
                title: 'Michigan (MI)',
                links: [
                    'Resale_MI.pdf'
                ]
            },
            {
                title: 'Minnesota (MN)',
                links: [
                    'Resale_MN.pdf'
                ]
            },
            {
                title: 'Missouri (MO)',
                links: [
                    'Resale_MO.pdf'
                ]
            },
            {
                title: 'Mississippi (MS)',
                links: [
                    'Resale_MS.pdf'
                ]
            },
            {
                title: 'North Carolina (NC)',
                links: [
                    'Resale_NC.pdf'
                ]
            },
            {
                title: 'North Dakota (ND)',
                links: [
                    'Resale_ND.pdf'
                ]
            },
            {
                title: 'Nebraska (NE)',
                links: [
                    'Resale_NE.pdf'
                ]
            },
            {
                title: 'New Jersey (NJ)',
                links: [
                    'Resale_NJ.pdf'
                ]
            },
            {
                title: 'New Mexico (NM)',
                links: [
                    'Resale_NM.pdf'
                ]
            },
            {
                title: 'Nevada (NV)',
                links: [
                    'Resale_NV.pdf'
                ]
            },
            {
                title: 'New York (NY)',
                links: [
                    'Resale_NY.pdf'
                ]
            },
            {
                title: 'Ohio (OH)',
                links: [
                    'Resale_OH.pdf'
                ]
            },
            {
                title: 'Oklahoma (OK)',
                links: [
                    'Resale_OK.pdf'
                ]
            },
            {
                title: 'Pennsylvania (PA)',
                links: [
                    'Resale_PA.pdf'
                ]
            },
            {
                title: 'Puerto Rico (PR)',
                links: [
                    'PUERTO-RICO-AS2916-FORM.pdf',
                    'PR-ELIGIBLE-RESELLER-2022.pdf'
                ]
            },
            {
                title: 'Rhode Island (RI)',
                links: [
                    'Resale_RI.pdf'
                ]
            },
            {
                title: 'South Carolina (SC)',
                links: [
                    'Resale_SC.pdf'
                ]
            },
            {
                title: 'South Dakota (SD)',
                links: [
                    'Resale_SD.pdf'
                ]
            },
            {
                title: 'Tennessee (TN)',
                links: [
                    'Resale_TN.pdf'
                ]
            },
            {
                title: 'Texas (TX)',
                links: [
                    'Resale_TX.pdf'
                ]
            },
            {
                title: 'Utah (UT)',
                links: [
                    'Resale_UT.pdf'
                ]
            },
            {
                title: 'Virginia (VA)',
                links: [
                    'Resale_VA.pdf'
                ]
            },
            {
                title: 'Vermont (VT)',
                links: [
                    'Resale_VT.pdf'
                ]
            },
            {
                title: 'Washington (WA)',
                links: [
                    'Resale_WA.pdf'
                ]
            },
            {
                title: 'Wisconsin (WI)',
                links: [
                    'Resale_WI.pdf'
                ]
            },
            {
                title: 'West Virginia (WV)',
                links: [
                    'Resale_WV.pdf'
                ]
            },
            {
                title: 'Wyoming (WY)',
                links: [
                    'Resale_WY.pdf'
                ]
            }
        ];

        var formattedCerts = [];

        certs.sort(function(a, b) {
            return b.title < a.title || 'Multijurisdiction' === b.title ? 1 : -1;
        });
        angular.forEach(certs, function(cert) {
            var links = [];
            cert.links.sort();
            angular.forEach(cert.links, function (link) {
                links.push({
                    label: link,
                    url: '/assets/data/resale-exemption-certificates/' + link
                });
            });
            formattedCerts.push({
                title: cert.title,
                links: links
            });
        });
        return formattedCerts;
    }
});
