(function () {
    'use strict';
}());

app.service('HomepageService', [
    'DOMAIN',
    function (DOMAIN) {
        var self = this;

        self.getCards = function () {
            var cards = [
                {
                    title: 'Solutions',
                    class: 'green',
                    items: [
                        { text: 'Exclusive Brands', wordpress: 'exclusive-brands'},
                        { text: 'Industrial Coatings', wordpress: 'industrialcoatings' },
                        { text: 'Integrated Solutions', wordpress: 'integrated-solutions' },
                        { text: 'Metalworking', wordpress: 'metalworking' },
                        { text: 'MROP', wordpress: 'mrop' },
                        { text: 'Safety Services', wordpress: 'safetyservices' },
                        { text: 'Value Plus', wordpress: 'vpp' }
                    ],
                    button: {
                        text: 'All Services & Solutions',
                        wordpress: 'servicesandsolutions'
                    }
                },
                {
                    title: 'Customer Quick Reference Links',
                    class: 'orange',
                    items: [
                        { text: 'Quotes', route: 'account.quote', loginRequired : true},
                        { text: 'Retrieve Invoices', route: 'account.paid_invoices' , loginRequired : true},
                        { text: 'Tracking Numbers', route: 'account.revieworders' , loginRequired : true},
                        { text: 'Open Invoices', route: 'account.open_invoices', loginRequired : true},
                        { text: 'Open Orders', route: 'account.revieworders', loginRequired : true},
                        { text: 'Order History', route: 'account.orders', loginRequired : true },
                        { text: 'Coronavirus Update', wordpress: 'coronavirus' }
                    ],
                    button: {
                        text: 'Additional Resources',
                        route: 'account.profile',
                        loginRequired : true
                    }
                },
                {
                    title: 'Quick Reference Documents',
                    class: 'grey',
                    items: [
                        { text: 'ISO-9001 Certificate', route: 'resources.iso_certificate' },
                        { text: 'Resale Exemption Certificates', route: 'resources.resale_exemption_certificates' },
                        { text: 'W9 Downloads', route: 'resources.w9_downloads' },
                        { text: 'Credit Application', route: 'account_create.customer' },
                        { text: 'Supplier Routing Guide', route: 'resources.supplier_routing_guide' }
                    ],
                    button: {
                        text: 'Additional Resources',
                        hash: 'footer'
                    }
                },
                {
                    title: 'Flexible Procurement Solutions<small><sup>&reg;</sup></small>',
                    class: 'blue',
                    items: [
                        { text: 'Web Features', wordpress: 'web-features'  },
                        { text: 'eProcurement', wordpress: 'eprocurement'  },
                        { text: 'Dispensing', wordpress: 'dispensing-solutions' }
                    ],
                    button: {
                        text: 'More Information',
                        wordpress: 'flexibleprocurement'
                    }
                },
                {
                    title: 'Customer Login',
                    class: 'green',
                    template: 'login_card'
                },
                {
                    title: 'Contact Form',
                    class: 'orange',
                    template: 'contact_card'
                }
            ];

            if(DOMAIN === 'boringsmith') {
                cards.unshift({
                    title: 'Industries',
                    class: 'red',
                    items: [
                        {text: 'Manufactured Housing', wordpress: 'rv'},
                        {text: 'Recreational Vehicle', wordpress: ''},
                        {text: 'Trailer & Specialty Vehicles', wordpress: ''},
                        {text: 'Recreational Marine industries', wordpress: ''},
                    ],
                    button: {
                        text: 'All Industries',
                        wordpress: 'industries'
                    }
                });
            } else {
                cards.unshift({
                    title: 'Industries',
                    class: 'blue',
                    items: [
                        { text: 'Aerospace', wordpress: 'aerospace-solutions' },
                        { text: 'Automotive', wordpress: 'automotive' },
                        { text: 'Heavy Equipment',wordpress: 'heavyequipment' },
                        { text: 'Marine', wordpress: 'marine' },
                        { text: 'PetroChem', wordpress: 'petrochem' },
                        { text: 'Transportation', wordpress: 'transportation' }
                    ],
                    button: {
                        text: 'All Industries',
                        wordpress: 'industries'
                    }
                });
            }
            return cards;
        };

        self.getSlides = function() {
            if (DOMAIN === 'boringsmith') {
                return [
                    {
                        src: '/assets/images/home/slide-boring-smith-1.jpg',
                        title: 'Boring-Smith',
                        subTitle: 'Serving the Recreational Vehicle Business Since 1972'
                    }
                ];
            }

            return [
                {
                    src: '/assets/images/home/slide-manufacturing.jpeg',
                    title: 'Proven Solutions',
                    subTitle: 'Comprehensive Supply Chain Solutions for all of your Indirect Materials Challenges'
                },
                {
                    src: '/assets/images/home/slide-safety_solutions.jpeg',
                    title: 'Safety Solutions',
                    subTitle: 'Protecting Your People, Plant and Life-Saving Equipment'
                },
                {
                    src: '/assets/images/home/slide-metalworking.jpeg',
                    title: 'Metalworking Solutions',
                    subTitle: 'Solutionist dedicated to Optimizing your Metalworking Tools and Processes'
                }
            ];
        };

        self.getPartnerLogos = function() {
            if (DOMAIN === 'boringsmith')
            {
                return [
                    {
                        src: '3M_logo.png',
                        route: 'wordpress({path: "3m"})'
                    },
                    {
                        src: 'Freud_logo.png'
                    },
                    {
                        src: 'Irwin_Tools_logo.png'
                    },
                    {
                        src: 'PIP_logo.png',
                        route: 'wordpress({path: "pip"})'
                    },
                    {
                        src: 'Sioux_Mast_logo.png'
                    },
                    {
                        src: 'Stanley_Black_Decker_logo.png',
                        route: 'wordpress({path: "sbd"})'
                    },
                    {
                        src: 'Vega-logo.png'
                    }
                ]
            }
            return [
                {
                    src: '3M_logo.png',
                    route: 'wordpress({path: "3m"})'
                },
                {
                    src: 'Ansell_logo.png',
                    route: 'wordpress({path: "ansell"})'
                },
                {
                    src: 'Dupont_Tyvek_logo.png',
                    route: 'wordpress({path: "dupont"})'
                },
                {
                    src: 'Honeywell_logo.png',
                    route: 'wordpress({path: "honeywell"})'
                },
                {
                    src: 'Industrial_Scientific_logo.png',
                    route: 'wordpress({path: "industrial-scientific"})'
                },
                {
                    src: 'Kcp_logo.png',
                    route: 'wordpress({path: "kimberly-clark"})'
                },
                {
                    src: 'PIP_logo.png',
                    route: 'wordpress({path: "pip"})'
                },
                {
                    src: 'Vestil_logo.png',
                    route: 'wordpress({path: "vestil"})'
                },
                {
                    src: 'Williams_logo.jpg',
                    route: 'wordpress({path: "snaponindustrial"})'

                }
            ];
        };
    }
]);
