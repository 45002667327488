(function () {
    'use strict';
}());

window.getEnv = function () {
    var env = 'local';
    switch(window.location.hostname) {
        case 'buy.vallen.com':
        case 'www.vallen.com':
        case 'vallen.com':
        case 'www.boringsmith.com':
        case 'boringsmith.com':
            env = 'production';
            break;
        case 'staging.vallen.com':
        case 'staging.boringsmith.com':
            env = 'staging';
            break;
        case 'dev.vallen.com':
            env = 'develop';
            break;
        case 'dev01.supplyandtool.com':
        case 'dev02.supplyandtool.com':
        case 'dev03.supplyandtool.com':
        case 'dev04.supplyandtool.com':
            env = 'devbox';
            break;
        default:
            env ='local';
    }
    return env;
};

//Sentry
try {
    if (!Raven || (window.getEnv() !== 'production' && window.getEnv() !== 'staging')) {
        angular.module('ngRaven', []);
    } else {
        Raven.config('https://aff0b7d2bc5849548a91b01095c9dcc4@sentry.io/172277', {
            environment: window.getEnv()
        }).install();
    }
} catch (e) {
    angular.module('ngRaven', []);
}

var $urlRouterProviderReference;

var app = angular.module(
    'app',
    [
        'ngRaven',
        'angularjs-dropdown-multiselect',
        'angular-inview',
        'angular-jwt',
        'angular-loading-bar',
        'growlNotifications',
        'algoliasearch',
        'ngAnimate',
        'ngAside',
        'ngCookies',
        'ngCapsLock',
        'ngFileSaver',
        'ngLodash',
        'ngSanitize',
        'ui.bootstrap',
        'ui.grid',
        'ui.grid.autoResize',
        'ui.grid.cellNav',
        'ui.grid.edit',
        'ui.grid.pinning',
        'ui.grid.resizeColumns',
        'ui.select',
        'angular.filter',
        'truncate',
        'ui.router',
        'uiRouterStyles',
        'ui.validate',
        'ui-rangeSlider',
        'angular-scroll-animate',
        'naif.base64',
        'angular-elevate-zoom',
        'credit-cards',
        'ngTable',
        'templates',
        'angularMoment',
        'daterangepicker'
    ]
);

// HTTP error handling
app.factory('httpRequestInterceptor', ['$q', '$rootScope', function ($q, $rootScope) {
    return {
        'responseError': function (rejection) {
            if (rejection.status == -1 ||
                rejection.status == 401 ||
                rejection.status == 403 ||
                rejection.status == 404) {
                return $q.reject(rejection);
            }

            var errorTitle = 'HTTP ' + rejection.status + ' - ' + rejection.statusText;
            var sentryError = {
                extra: {
                    data: rejection.data,
                    config: rejection.config,
                    status: rejection.status,
                    statusText: rejection.statusText
                }
            };

            // Only send custom information to Sentry.
            // This will trigger when an option called "customErrorName" is added to the $http method
            if(rejection.config.customErrorName){
                switch (rejection.config.customErrorName){
                    case 'submitOrderByOpenBrowse':
                    case 'submitOrderByToken':
                        delete sentryError.extra.config.data;
                        break;
                    default:
                        break;
                }
            }

            Raven
                .setUserContext($rootScope.currentUser)
                .captureException(new Error(errorTitle), sentryError);

            return $q.reject(rejection);
        }
    };
}]);

app.config([
    '$httpProvider',
    '$provide',
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    'cfpLoadingBarProvider',
    'LIVE',
    'WORDPRESS_URL',
    '$sceDelegateProvider',
    '$cookiesProvider',
    'DOMAIN',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    function ($httpProvider, $provide, $stateProvider, $urlRouterProvider, $locationProvider, cfpLoadingBarProvider, LIVE, WORDPRESS_URL, $sceDelegateProvider, $cookiesProvider, DOMAIN, OPEN_BROWSE_CUSTOMER_NUMBER) {
        $httpProvider.interceptors.push('httpRequestInterceptor');

        if (LIVE) {
            var env = window.getEnv();

            switch (DOMAIN) {
                case 'boringsmith':
                    $cookiesProvider.defaults.domain = '.boringsmith.com';
                    break;
                default:
                    $cookiesProvider.defaults.domain = (env === 'devbox') ? '.supplyandtool.com' : '.vallen.com';
                    break;
            }
        }
        $cookiesProvider.defaults.samesite = 'None';
        $cookiesProvider.defaults.secure = true;

        $provide.decorator('$exceptionHandler', [
            '$log',
            '$delegate',
            function ($log, $delegate) {
                return function (exception, cause) {
                    $log.debug('Sentry exception handler.');
                    Raven.captureException(exception);
                    $delegate(exception, cause);
                };
            }
        ]);

        cfpLoadingBarProvider.includeSpinner = false;

        $locationProvider.html5Mode(LIVE);

        $urlRouterProvider.otherwise(function ($injector, $location) {
            var redirectTo = '/404';
            if ($location.$location.$$path) {
                var locPath = $location.$location.$$path;
                if (locPath.startsWith('/catalog/')) {
                    redirectTo = locPath.replace('/catalog/', '/c/').replace(/\/$/, "");
                }

                var redirects = [
                    {
                        "route": "/houston",
                        "redirectTo": "/w/houston"
                    },
                    {
                        "route": "/advancemycareer",
                        "redirectTo": "/w/advancemycareer"
                    },
                    {
                        "route": "/solutions",
                        "redirectTo": "/w/vallensolutionist"
                    },
                    {
                        "route": "/marine",
                        "redirectTo": "/w/marine"
                    }
                ];
                for (var i = 0; i < redirects.length; i++) {
                    var currentRoute = redirects[i];
                    if (locPath.replace(/\/$/, "").toLowerCase() === currentRoute.route.toLowerCase()) {
                        redirectTo = currentRoute.redirectTo;
                        break;
                    }
                }
            }
            $location.url(redirectTo);
        });

        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            WORDPRESS_URL + '/**'
        ]);

        /*
            domain-specific states
            - defaults are Vallen routes, overridden if necessary
         */
        var contactState = {
            url: '/contact-us',
            templateUrl: 'views/contact/contact.html',
            data: {
                title: 'Contact Us'
            }
        };

        var aboutState = {
            url: '/about',
            controller: ['$state', function($state) {
                $state.go('wordpress', {path: 'aboutus'});
            }],
            data: {}
        };

        if(DOMAIN === 'boringsmith') {
            aboutState = {
                url: '/about',
                templateUrl: 'views/base/aboutUs.html',
                data: {
                    title: "About Us"
                }
            };
        }

        $stateProvider
            .state('404', {
                url: '/404',
                templateUrl: 'views/base/404.html',
                controller: 'BodyController',
                data: {
                    title: '404 Page Not Found'
                }
            })
            .state('maintenance', {
                url: '/maintenance',
                templateUrl: 'views/_partials/maintenance.html',
                controller: 'MaintenanceController',
                data: {
                    title: 'Down For System Maintenance'
                }
            })
            .state('unauthorized', {
                url: '/unauthorized',
                templateUrl: 'views/base/unauthorized.html',
                controller: 'BodyController',
                data: {
                    title: 'Access Denied'
                }
            })
            .state('wordpress', {
                url: "/w/:path",
                templateUrl: 'views/wordpress/wordpress.html',
                controller: 'WordPressController',
                data: {
                    title: ''
                },
                params: {
                    path: '[a-zA-Z0-9\-]+$'
                }
            })
            .state('account', {
                abstract: true,
                url: '/my-account',
                templateUrl: 'views/account/account.html',
                controller: 'AccountController',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                },
                data: {
                    title: 'My Account',
                    requireLogin: true
                }
            })
            .state('account.profile', {
                url: '',
                controller: 'AccountProfileController',
                templateUrl: 'views/account/account.profile.html',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                }
            })
            .state('account.scan_to_web', {
                url: "/scan-to-web",
                templateUrl: 'views/account/scan-to-web/scan-to-web.html',
                controller: 'ScanToWebController',
                data: {
                    title: "Scan To Web"
                },
                resolve: {
                    user: ['UserService', '$state', '$rootScope', function (UserService, $state, $rootScope) {
                        return UserService.getUser().then(function (user) {
                            if (!user) {
                                var previousState = {
                                    state: 'account.scan_to_web',
                                }
                                UserService.logout(false);
                                $state.go('account_login', previousState);
                            }

                            return user;
                        }, function(err) {
                            var previousState = {
                                state: 'account.scan_to_web'
                            };
                            UserService.logout(false, previousState);
                            $state.go('account_login');
                            return false;
                        });
                    }]
                },
            })
            .state('account.scan_to_web_details', {
                url: "/scan-to-web/:id?status",
                templateUrl: 'views/account/scan-to-web/scan-to-web.details.html',
                controller: 'ScanToWebDetailsController',
                data: {
                    title: "Scan To Web"
                },
                params: {
                    path: '[a-zA-Z0-9\-]+$'
                },
                resolve: {
                    user: ['UserService', '$state', '$stateParams', function (UserService, $state, $stateParams) {
                        return UserService.getUser().then(function (user) {
                            if (!user) {
                               var previousState = {
                                    state: 'account.scan_to_web_details',
                                    stateParams: {id: $stateParams.id}
                                }
                                UserService.logout(false);
                                $state.go('account_login', previousState);
                            }

                            return user;
                        }, function(err) {
                            var previousState = {
                                state: 'account.scan_to_web_details',
                                stateParams: {id: $stateParams.id}
                            }
                            UserService.logout(false);
                            $state.go('account_login', previousState);
                            return false;
                        });
                    }],
                    scan: ['ScanToWebService', '$state', '$stateParams', '$rootScope', function (ScanToWebService, $state, $stateParams, $rootScope) {
                        return ScanToWebService.getScanById($stateParams.id, $stateParams.status).then( function (response) {
                            var scan = response.scans;
                            if (!scan || !scan.length) {
                                $state.go('account.scan_to_web');
                                $rootScope.$broadcast(
                                    'notify', 
                                    { message: 'Sorry, unable to display details. Please try again' }
                                );
                            } else {
                                return scan[0];
                            }
                        });
                    }]
                },
            })
            .state('account.shared_carts', {
                url: "/shared-carts",
                templateUrl: 'views/account/account.shared_carts.html',
                controller: 'AccountSharedCartsController',
                data: {
                    title: "Shared Carts"
                }
            })
            .state('account.shopping_lists', {
                url: "/shopping-lists",
                templateUrl: 'views/account/shopping-lists/shopping-lists.html',
                controller: 'ShoppingListsController',
                resolve: {
                    lists: ['ShoppingListService', function (ShoppingListService) {
                        return ShoppingListService.getLists({limit: 1000});
                    }]
                },
                data: {
                    title: "Shopping Lists"
                }
            })
            .state('account.shopping_list_detail', {
                url: '/shopping-list/{list:[a-z0-9_-]+$}',
                templateUrl: 'views/account/shopping-lists/shopping-list-detail.html',
                controller: 'ShoppingListDetailController',
                resolve: {
                    list: ['ShoppingListService', '$stateParams', function (ShoppingListService, $stateParams) {
                        return ShoppingListService.getList($stateParams.list, true);
                    }]
                },
                params: {
                    list: null
                }
            })
            .state('account.summary', {
                url: "/summary",
                templateUrl: 'views/account/account.summary.html',
                controller: 'AccountSummaryController',
                data: {
                    title: "Account Summary"
                }
            })
            .state('account.orders', {
                url: '/orders',
                controller: 'AccountReviewOrdersController',
                templateUrl: 'views/account/account.orders.html',
                data: {
                    title: 'My Web Orders'
                }
            })
            .state('account.reviewquotes', {
                url: '/reviewquotes',
                controller: 'AccountReviewOrdersController',
                templateUrl: 'views/account/account.review.quotes.html',
                data: {
                    title: 'Review Quotes',
                    orderType: 'QU'
                }
            })
            .state('account.review_quote_details', {
                url: '/review-quote-details/:order_number?c',
                controller: 'AccountOrderDetailsController',
                templateUrl: 'views/account/account.order_details.html',
                data: {
                    title: 'Review Quote Details',
                    orderType: 'QU'
                }
            })
            .state('account.revieworders', {
                url: '/revieworders',
                controller: 'AccountReviewOrdersController',
                templateUrl: 'views/account/account.review.orders.html',
                data: {
                    title: 'Review Orders'
                }
            })
            .state('account.order_details', {
                url: '/order-details/:order_number?c',
                controller: 'AccountOrderDetailsController',
                templateUrl: 'views/account/account.order_details.html',
                data: {
                    title: 'Order Details'
                }
            })
            .state('account.review_order_details', {
                url: '/review-order-details/:order_number?c',
                controller: 'AccountOrderDetailsController',
                templateUrl: 'views/account/account.order_details.html',
                data: {
                    title: 'Review Order Details'
                }
            })
            .state('account.open_invoices', {
                url: '/invoices/open',
                controller: 'AccountReviewOrdersController',
                templateUrl: 'views/account/account.review.invoices.html',
                data: {
                    stageCd: 4,
                    title: 'Open Invoices'
                }
            })
            .state('account.paid_invoices', {
                url: '/invoices/paid',
                controller: 'AccountReviewOrdersController',
                templateUrl: 'views/account/account.review.invoices.html',
                data: {
                    stageCd: 5,
                    title: 'Paid Invoices'
                }
            })
            .state('account.shipping', {
                url: '/shipping-addresses',
                controller: 'AccountShippingController',
                templateUrl: 'views/account/account.shipping.html'
            })
            .state('account.billing', {
                url: '/billing-info',
                controller: 'AccountBillingController',
                templateUrl: 'views/account/account.billing.html'
            })
            .state('account.non_stock', {
                url: '/non-stock',
                controller: 'AccountNonStockController',
                templateUrl: 'views/account/account.nonstock_quote.html',
                data: {
                    title: 'Order Non-Stock',
                    orderType: 'O'
                }
            })
            .state('account.quote', {
                url: '/quote',
                controller: 'AccountNonStockController',
                templateUrl: 'views/account/account.nonstock_quote.html',
                data: {
                    title: 'Request Quote',
                    orderType: 'Q'
                }
            })
            .state('account.cart_requests', {
                url: '/requests',
                controller: 'AccountCartRequestsController',
                templateUrl: 'views/account/cart-requests/account.cart_requests.html',
                data: {
                    title: 'My Orders'
                }
            })
            .state('account.frc_manager', {
                url: "/frc-manager",
                controller: 'AccountFrcManagerController',
                templateUrl: 'views/account/account.frc_manager.html'
            })
            .state('account_create', {
                url: '/create-account',
                controller: 'RegistrationController',
                templateUrl: 'views/registration/template.html',
                redirectTo: 'account_create.lookup',
                resolve: {
                    isAuthenticated: ['UserService', function (UserService) {
                        return UserService.getIsAuthenticated();
                    }]
                }
            })
            .state('account_create.lookup', {
                url: '',
                controller: 'RegistrationLookupController',
                templateUrl: 'views/registration/lookup.html',
                data: {
                    title: 'Register Now'
                }
            })
            .state('account_create.register', {
                url: '/register',
                controller: 'RegistrationRegisterController',
                templateUrl: 'views/registration/register.html',
                data: {
                    title: 'Register Now - Create User Profile'
                },
                params: {
                    customerNumber: null,
                    regType: 'new',
                    userType: 'Customer'
                }
            })
            .state('account_create.supplier', {
                url: '/supplier',
                controller: 'RegistrationRegisterController',
                templateUrl: 'views/registration/register.html',
                data: {
                    title: 'Supplier Registration'
                },
                params: {
                    customerNumber: OPEN_BROWSE_CUSTOMER_NUMBER,
                    regType: 'supplier',
                    userType: 'Supplier'
                }
            })
            .state('account_create.vallen', {
                url: '/vallen',
                controller: 'RegistrationRegisterController',
                templateUrl: 'views/registration/register.html',
                data: {
                    title: 'Vallen Associate Registration'
                },
                params: {
                    customerNumber: OPEN_BROWSE_CUSTOMER_NUMBER,
                    regType: 'vallen',
                    userType:'Vallen Associate'
                }
            })
            .state('account_create.customer', {
                url: '/customer',
                controller: 'RegistrationCustomerController',
                templateUrl: 'views/registration/customer.html',
                data: {
                    title: 'Customer Application'
                }
            })
            .state('account_create.customer_confirmation', {
                url: '/customer-confirmation',
                templateUrl: 'views/registration/customer-confirmation.html',
                data: {
                    title: 'Customer Application Confirmation'
                }
            })
            .state('account_create.pending', {
                url: '/pending',
                templateUrl: 'views/registration/pending.html',
                data: {
                    title: 'Registration Pending'
                }
            })
            .state('account_login', {
                url: '/login',
                templateUrl: 'views/account/login.html',
                controller: 'LoginController',
                data: {
                    title: "Log In"
                },
                params: {
                    state: null,
                    stateParams: null
                },  
                resolve: {
                    isAuthenticated: ['UserService', function (UserService) {
                        return UserService.getIsAuthenticated();
                    }]
                }
            })
            .state('account_password_recovery', {
                url: '/password-recovery',
                templateUrl: 'views/account/passwordRecovery.html',
                data: {
                    title: "Password Recovery"
                }
            })
            .state('account_password_reset_welcome', {
                url: '/reset-welcome?u&p',
                templateUrl: 'views/account/passwordResetWelcome.html',
                data: {
                    title: "Password Reset"
                }
            })
            .state('account_password_reset_welcome_confirmed', {
                url: '/reset-welcome-confirmed',
                templateUrl: 'views/account/tour.html',
                data: {
                    title: "Password Reset"
                },
                controller: ['$rootScope', '$scope', function($rootScope, $scope) {
                    $scope.passwordReset = true;
                }]
            })
            .state('tour', {
                url: '/tour',
                templateUrl: 'views/account/tour.html',
                data: {
                    title: "Tour"
                },
                controller: ['$rootScope', '$scope', function($rootScope, $scope) {
                    $scope.passwordReset = false;
                }]
            })
            .state('account_password_reset', {
                url: '/reset?h&u&i',
                templateUrl: 'views/account/passwordReset.html',
                data: {
                    title: "Password Reset"
                }
            })

            // Admin panel start
            .state('admin', {
                abstract: true,
                url: '/administration',
                templateUrl: 'views/admin/admin.html',
                controller: 'AdminController',
                resolve: {
                    user: ['UserService', '$state', function (UserService, $state) {
                        return UserService.getUser().then(function (user) {
                            if (!user) {
                                UserService.logout(false);
                                $state.go('account_login');
                            }

                            return user;
                        }, function(err) {
                            UserService.logout(false);
                            $state.go('account_login');
                            return false;
                        });
                    }]
                },
                data: {
                    title: 'Administration',
                    requireLogin: true
                }
            })

            .state('admin.profile_requests', {
                url: '/profile-requests?s&a&r',
                controller: 'AdminCdfRequestsController',
                templateUrl: 'views/admin/admin.cdf_requests.html',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                },
                data: {}
            })

            .state('admin.index', {
                url: '',
                controller: 'AdminIndexController',
                templateUrl: 'views/admin/admin.index.html'
            })

            .state('admin.order_requests', {
                url: '/order-requests?s&a&r',
                controller: 'AdminOrderRequestsController',
                templateUrl: 'views/admin/admin.order_requests.html',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                },
                data: {}
            })

            .state('admin.users', {
                url: '/users',
                controller: 'AdminUsersController',
                templateUrl: 'views/admin/admin.users.html'
            })

            .state('account.user_setup', {
                url: '/user-setup',
                controller: 'AccountUserSetupController',
                templateUrl: 'views/account/account.user_setup.html'
            })

            .state('account.universal_crib', {
                url: '/universal-crib',
                controller: 'UniversalCribController',
                templateUrl: 'views/account/account.universal_crib.html'
            })

            .state('admin.departments', {
                url: '/departments',
                controller: 'AdminDepartmentsController',
                templateUrl: 'views/admin/admin.departments.html'
            })
            // Admin panel end

            .state('contact', contactState)

            .state('about', aboutState)

            .state('terms_and_conditions', {
                url: '/terms-of-service',
                controller: ['$state', function($state) {
                    $state.go('termsandconditions', {});
                }]
            })

            .state('termsandconditions', {
                url: '/termsandconditions',
                templateUrl: 'views/base/termsAndConditions.html',
                data: {
                    title: 'Terms and Conditions'
                }
            })

            .state('cart', {
                templateUrl: 'views/cart/cart.html',
                controller: 'CartController',
                redirectTo: 'cart.summary',
                resolve: {
                    cartSessionId: ['$stateParams', function($stateParams) {
                        return $stateParams.cartSessionId;
                    }]
                },
                params: {
                    cartSessionId: null
                },
                data: {
                    title: "Your Cart",
                    requireLogin: true
                }
            })
            .state('cart.summary', {
                url: "/cart",
                templateUrl: 'views/cart/_partials/summary.html',
                controller: 'CartChildController',
                data: {
                    title: "Your Cart"
                }
            })
            .state('cart.login', {
                url: "/cart/log-in",
                templateUrl: 'views/cart/_partials/checkoutAs.html',
                controller: 'CartChildController',
                data: {
                    title: "Checkout As"
                }
            })
            .state('cart.order', {
                url: "/cart/order",
                templateUrl: 'views/cart/_partials/orderDetails.html',
                controller: 'CartChildController',
                data: {
                    title: "Order Details"
                }
            })
            .state('cart.payment', {
                url: "/cart/payment",
                templateUrl: 'views/cart/_partials/payment.html',
                controller: 'CartChildController',
                data: {
                    title: "Order Payment"
                }
            })
            .state('cart.review', {
                url: "/cart/review",
                templateUrl: 'views/cart/_partials/review.html',
                controller: 'CartChildController',
                data: {
                    title: "Order Review"
                }
            })
            .state('cart.confirm', {
                url: "/cart/confirm",
                templateUrl: 'views/cart/_partials/confirmation.html',
                controller: 'CartChildController',
                data: {
                    title: "Order Confirmation"
                }
            })
            .state('cart.approval', {
                url: "/cart/approval",
                templateUrl: 'views/cart/_partials/approval.html',
                controller: 'CartChildController',
                data: {
                    title: "Cart Submitted for Approval"
                }
            })
            .state('cart.error', {
                url: "/cart/error",
                templateUrl: 'views/cart/_partials/error.html',
                controller: 'CartChildController',
                data: {
                    title: "Order Exception"
                }
            })

            .state('cart_shared', {
                url: '/cart/shared/{cart:[a-z0-9]+$}',
                templateUrl: 'views/cart/cart.shared.html',
                controller: 'ShareCartController',
                resolve: {
                    cart: ['CartService', '$stateParams', function (CartService, $stateParams) {
                        return CartService.getCartData($stateParams.cart);
                    }]
                },
                params: {
                    cart: null
                },
                data: {
                    title: "Shared Cart"
                }
            })

            .state('new_catalog', {
                url: '/c?q&department&category&style&type&vendor&filters&brands&catalog',
                controller: 'CatalogController',
                templateUrl: 'views/catalog/catalog.html',
                reloadOnSearch: false,
                resolve: {
                    dbFilter: function () {
                        return false;
                    }
                },
                data: {
                    requireLogin: true
                }
            })

            .state('catalog_prefiltered', {
                url: '/eb/{slug:[a-zA-Z0-9\-_]+}',
                controller: 'CatalogController',
                templateUrl: 'views/catalog/catalog.html',
                reloadOnSearch: false,
                resolve: {
                    dbFilter: ['CatalogService', '$stateParams', function (CatalogService, $stateParams) {
                        return CatalogService.getSearchFilter($stateParams.slug);
                    }]
                },
                data: {
                    requireLogin: true
                }
            })

            .state('brands', {
                url: '/brands',
                templateUrl: 'views/catalog/brands.html',
                controller: 'BrandsController',
                data: {
                    title: "Brand Name Industrial Suppliers"
                }
            })

            .state('catalog', {
                url: '/c',
                abstract: true,
                templateUrl: 'views/catalog/catalog.html',
                data: {
                    requireLogin: true
                }
            })
            .state('catalog_brand', {
                url: '/brand/:slug?q',
                controller: [
                    '$rootScope',
                    'AlgoliaService',
                    '$stateParams',
                    '$state',
                    function ($rootScope, AlgoliaService, $stateParams, $state) {
                        AlgoliaService.searchTaxonomy({
                            term: $stateParams.slug,
                            hitsPerPage: 10,
                            facetFilters: [['type:vendor']]
                        }).then(function (brands) {
                            if (brands && brands.hits && brands.hits.length) {
                                var brand = null;
                                angular.forEach(brands.hits, function(b) {
                                    if (b.name == $stateParams.slug || b.slug == $stateParams.slug) {
                                        brand = b;
                                        return false;
                                    }
                                });

                                if (brand) {
                                    if (brand.override && brand.override.startsWith('wordpress({path:\'')) {
                                        var path = brand.override.replace('wordpress({path:\'', '').replace('\'})', '');
                                        $state.go('wordpress', {
                                            path: path
                                        });
                                    } else {
                                        var params = $rootScope.mergeBlankParams({vendor: brand.name});
                                        if ($stateParams.q) {
                                            params.q = $stateParams.q;
                                        } else {
                                            params.brands = true;
                                        }
                                        $state.go('new_catalog', params);
                                    }
                                } else {
                                    $state.go('404');
                                }
                            } else {
                                $state.go('404');
                            }
                        }, function () {
                            $state.go('404');
                        });
                    }
                ],
                data: {
                    title: 'Brand',
                    requireLogin: true
                }
            })
            .state('catalog.department', {
                url: '/{department:[a-zA-Z0-9-]+}/{catalog:[a-zA-Z0-9-]+$}',
                params: {
                    department: '[a-zA-Z0-9\-]+$',
                    catalog: null
                },
                redirectTo: 'new_catalog'
            })
            .state('catalog.category', {
                url: '/{department:[a-zA-Z0-9\-]+}/{category:[a-zA-Z0-9\-]+}/{catalog:[a-zA-Z0-9-]+$}',
                templateUrl: 'views/catalog/catalog.items.html',
                params: { catalog: null },
                redirectTo: 'new_catalog'
            })
            .state('catalog.style', {
                url: '/{department:[a-zA-Z0-9\-]+}/{category:[a-zA-Z0-9\-]+}/{style:[a-zA-Z0-9\-]+$}',
                redirectTo: 'new_catalog'
            })
            .state('catalog.type', {
                url: '/{department:[a-zA-Z0-9\-]+}/{category:[a-zA-Z0-9\-]+}/{style:[a-zA-Z0-9\-]+}/{type:[a-zA-Z0-9\-]+$}',
                redirectTo: 'new_catalog'
            })

            .state('catalog_product', {
                url: '/p/{slug:[a-zA-Z0-9\-_]+}',
                templateUrl: 'views/catalog/product.html',
                controller: 'ProductController',
                data: {
                    title: "Product"
                }
            })

            .state('catalog_product_erp', {
                url: '/p/item/{erp:[a-zA-Z0-9\-_]+}',
                controller: [
                    '$rootScope',
                    'AlgoliaService',
                    '$stateParams',
                    '$state',
                    function ($rootScope, AlgoliaService, $stateParams, $state) {
                        AlgoliaService.searchProducts({
                            term: $stateParams.erp
                        }).then(function(data) {
                            if (data && data.hits && data.hits.length) {
                                var product = data.hits[0];
                                angular.forEach(product.items, function(item) {
                                    if ( item.cpn === $stateParams.erp || item.erp === $stateParams.erp) {
                                        $rootScope.selectedItem = $stateParams.erp;
                                        return false;
                                    }
                                });

                                if ($rootScope.selectedItem) {
                                    $state.go('catalog_product', {slug: product.slug});
                                } else {
                                    $state.go('404');
                                }
                            } else {
                                $state.go('404');
                            }
                        }, function () {
                            $state.go('404');
                        });
                    }
                ],
                data: {
                    title: "Product"
                }
            })

            .state('categories', {
                url: '/categories',
                templateUrl: 'views/catalog/categories.html',
                controller: 'CategoriesController',
                data: {
                    title: "Shop by Category"
                }
            })

            .state('home', {
                url: '/',
                views: {
                    '': {templateUrl: 'views/base/home.html',
                        controller: 'HomeController'
                    },
                    'login_card@home': {
                        templateUrl: 'views/base/_partials/homeLogin.html',
                        controller: 'LoginCardController'
                    },
                    'contact_card@home': {
                        templateUrl: 'views/base/_partials/homeContactForm.html',
                        controller: 'ContactCardController'
                    }
                },
                data: {
                    title: 'Home'
                }
            })

            .state('strategic_sourcing', {
                url: "/strategic-sourcing",
                templateUrl: 'views/strategic_sourcing/strategic_sourcing.html',
                controller: 'StrategicSourcingController',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                },
                data: {
                    title: 'Strategic Sourcing'
                }
            })
            .state('order_pad', {
                abstract: true,
                url: "/order-pad",
                templateUrl: 'views/order_pad/order_pad.html',
                data: {
                    title: 'Order Pad',
                    requireLogin: true
                }
            })
            .state('order_pad.single_entry', {
                url: '/single-entry',
                templateUrl: 'views/order_pad/_partials/order_pad.single_entry.html'
            })
            .state('order_pad.import', {
                url: '/import',
                templateUrl: 'views/order_pad/_partials/order_pad.import.html'
            })
            .state('order_pad.paste', {
                url: '/paste',
                templateUrl: 'views/order_pad/_partials/order_pad.paste.html'
            })
            .state('locations', {
                url: '/locations',
                templateUrl: 'views/locations/locations.html',
                controller: 'LocationsController',
                data: {
                    title: 'Locations Finder'
                }
            })
            .state('privacy_policy', {
                url: '/privacy-policy',
                templateUrl: 'views/base/privacyPolicy.html',
                data: {
                    title: 'Privacy Policy'
                }
            })
            .state('return_policy', {
                url: '/return-policy',
                templateUrl: 'views/base/returnPolicy.html',
                data: {
                    title: 'Return Policy'
                }
            })
            .state('shipping_information', {
                url: '/shipping-information',
                templateUrl: 'views/base/shippingInformation.html',
                data: {
                    title: 'Shipping Information'
                }
            })

            .state('support', {
                url: '/support',
                templateUrl: 'views/support/support.html',
                controller: 'SupportController',
                data: {
                    title: 'Knowledge Base Support'
                }
            })
            .state('resources',{
                url: '/resources',
                abstract: true,
                template: '<ui-view/>'
            })
            .state('resources.supplier_routing_guide', {
                url: '/supplier-routing-guide',
                templateUrl: 'views/resources/supplier-routing-guide.html',
                controller: 'ResourcesSupplierRoutingGuideController',
                data: {
                    title: 'Supplier Routing Guide'
                }
            })
            .state('resources.w9_downloads',{
                url: '/w9-downloads',
                templateUrl: 'views/resources/w9-downloads.html',
                controller: 'ResourcesW9DownloadsController',
                data:{
                    title: "W9 Downloads"
                }
            })
            .state('resources.resale_exemption_certificates',{
                url: '/resale-exemption-certificates',
                templateUrl: 'views/resources/resale-exemption-certificates.html',
                controller: 'ResourcesResaleExemptionCertController',
                data:{
                    title: 'Resale Exemption Certificates'
                }
            })
            .state('resources.2021_blow_out_sale',{
                url: '/2021-blow-out-sale',
                externalUrl: '/assets/data/Vallen-2021-Blow-Out-Event.pdf'
            })
            .state('resources.iso_certificate',{
                url: '/iso-certificate',
                externalUrl: '/assets/data/Vallen-ISO-9001-Certificate.pdf'
            })
            .state('resources.credit_application',{
                url: '/credit_application',
                externalUrl: 'https://intranet.vallen.com/WCA/custcred.nsf'
            })
            .state('vallen_tools', {
                abstract: true,
                url: '/vallen-tools',
                templateUrl: 'views/vallen_tools/vallen_tools.html',
                controller: 'VallenToolsController',
                resolve: {
                    user: ['UserService', '$state', function (UserService, $state) {
                        return UserService.getUser().then(function (user) {
                            if (!user) {
                                UserService.logout(false);
                                $state.go('account_login');
                            }

                            return user;
                        }, function() {
                            UserService.logout(false);
                            $state.go('account_login');
                            return false;
                        });
                    }]
                },
                data: {
                    title: 'Vallen Tools',
                    requireLogin: true
                }
            })
            .state('vallen_tools.vendors', {
                url: '/vendors',
                controller: 'VallenToolsVendorsController',
                templateUrl: 'views/vallen_tools/vallen_tools.vendors.html',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                },
                data: {
                    title: 'Vendor Lookup'
                }
            })
            .state('vallen_tools.customers', {
                url: '/customers',
                controller: 'VallenToolsCustomersController',
                templateUrl: 'views/vallen_tools/vallen_tools.customers.html',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                },
                data: {
                    title: 'Customer Lookup'
                }
            })
            .state('vallen_tools.customer_applications', {
                url: '/customer-applications',
                controller: 'VallenToolsCustomerApplicationsController',
                templateUrl: 'views/vallen_tools/vallen_tools.customer_applications.html',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                },
                data: {
                    title: 'Customer Applications'
                }
            })
            .state('vallen_tools.customer_application', {
                url: '/customer-application/:id',
                controller: 'VallenToolsCustomerApplicationController',
                templateUrl: 'views/vallen_tools/vallen_tools.customer_application.html',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                },
                data: {
                    title: 'Customer Application Edit'
                }
            })
            .state('vallen_tools.products', {
                url: '/products?erp&custnum',
                controller: 'VallenToolsProductsController',
                templateUrl: 'views/vallen_tools/vallen_tools.products.html',
                resolve: {
                    user: ['UserService', function (UserService) {
                        return UserService.getUser();
                    }]
                },
                data: {
                    title: 'Product Lookup'
                }
            })
            .state('careers',{
                externalUrl: 'https://vallen-careers.silkroad.com/'
            })
        ;
    }
]);

function loadAnalytics() {

    //Woopra
    !function(){var a,b,c,d=window,e=document,f=arguments,g="script",
        h=["config","track","trackForm","trackClick","identify","visit","push","call"],
        i=function(){var a,b=this,c=function(a){b[a]=function(){return b._e.push([a].concat(Array.prototype.slice.call(arguments,0))),b}};
        for(b._e=[],a=0;a<h.length;a++)c(h[a])};
        for(d.__woo=d.__woo||{},a=0;a<f.length;a++)d.__woo[f[a]]=d[f[a]]=d[f[a]]||new i;
        b=e.createElement(g),b.async=1,b.src="//static.woopra.com/js/w.js",
            c=e.getElementsByTagName(g)[0],c.parentNode.insertBefore(b,c)}("woopra");

    if(window.woopra) {
        var woopraConfig = {};

        //Determine environment for where to send analytics data
        if (window.getEnv() === 'production') {
            ga('create', 'UA-83229119-1', 'auto');
            woopraConfig.domain = 'vallen.com';
        } else {
            ga('create', 'UA-83229119-2', 'auto');
            woopraConfig.domain = 'vallen-dev.com';
        }

        window.woopra.config(woopraConfig);
    }

    //Google Analytics
    ga('require', 'GTM-TBP4ZDS');
    ga('require', 'ec');

    //Hotjar
    (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () {
                (h.hj.q = h.hj.q || []).push(arguments)
            };
        h._hjSettings = {hjid: 386566, hjsv: 5};
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
}

function loadIntercom(user_id, name, email, created_at) {
    window.intercomSettings = {
        app_id: "dola4wvo"
    };

    if (user_id) {
        window.intercomSettings.user_id = user_id;
    }

    if (name) {
        window.intercomSettings.name = name;
    }

    if (email) {
        window.intercomSettings.email = email;
    }

    if (created_at) {
        window.intercomSettings.created_at = created_at;
    }

    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments)
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args)
            };
            w.Intercom = i;
            function
            l() {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/dola4wvo';
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            }

            if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();

    Intercom('onShow', function () {
        // open chat
    });

    Intercom('onHide', function () {
        // close chat
    });
}

function loadFonts() {
    angular.element(document.body).append(angular.element('<link href="https://fonts.googleapis.com/css?family=Titillium+Web:200,400,700" rel="stylesheet" type="text/css">'));
    angular.element(document.body).append(angular.element('<link href="https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,800italic,400" rel="stylesheet" type="text/css">'));
    angular.element(document.body).append(angular.element('<link href="https://fonts.googleapis.com/css?family=Montserrat:400,600" rel="stylesheet" type="text/css">'));
}

app.run(['$rootScope', '$window', '$state', '$location', '$cookies', '$transitions', 'UserService', 'FilterService', 'AnalyticsService', 'S3_RESOURCES', 'PRODUCT_RESOURCES', 'PUNCHOUT_SRC', 'DOMAIN', 'MAINTENANCE',
    function ($rootScope, $window, $state, $location, $cookies, $transitions, UserService, FilterService, AnalyticsService, S3_RESOURCES, PRODUCT_RESOURCES, PUNCHOUT_SRC, DOMAIN, MAINTENANCE) {
    $rootScope.typeOf = function(value) {
        return typeof value;
    };
    $rootScope.siteMaintenance = MAINTENANCE;
    setTimeout(function () {
        loadAnalytics();
        loadFonts();
    }, 100);

    var resetLocalStorageOverride = function () {
        $window.localStorageOverride = {};
        $window.localStorageOverride.clear = function() {
            resetLocalStorageOverride();
        };
    };

    var checkPunchoutLogin = function () {
        if (document.referrer.indexOf(PUNCHOUT_SRC) !== -1) {
            $cookies.put("isPunchOut", "1");
            $rootScope.punchout = true;
            resetLocalStorageOverride();
        } else {
            $rootScope.punchout = false;
            $cookies.remove("isPunchOut");
            $cookies.remove("punchoutHook");
            $cookies.remove("user");
            $cookies.remove("sessionID");
        }
        return false;
    };

    if (!isPrivateBrowsingSupported()) {
        console.log('Local storage not supported.');
        resetLocalStorageOverride();
    } else {
        $window.localStorageOverride = $window.localStorage;
    }

    checkPunchoutLogin();

    FilterService.initFilters();

    $rootScope.s3Resources = S3_RESOURCES;
    $rootScope.productResources = PRODUCT_RESOURCES;

    $rootScope.mergeBlankParams = function(params) {
        return angular.merge({
            q: null,
            department: null,
            category: null,
            style: null,
            type: null,
            vendor: null,
            filters: null,
            brands: null,
            catalog: null
        }, params);
    };

    $rootScope.productImageSrc = function(item) {
        // handle product images during catalog transition (different formats)
        if (!item || !item.image) {
            return $rootScope.s3Resources + '/catalog/products/fallback_horizontal_190x140.gif';
        }
        if (item.image.toLowerCase().substring(0, 4) !== 'http') {
            return $rootScope.productResources + '/products/sm/' + item.image;
        } else {
            return item.image;
        }
    };

    var originalDescription = $window.document.getElementsByName('description')[0].content;

    $transitions.onStart({}, function(transition){
        var to = transition.to();
        if (to.redirectTo) {
            $state.go(to.redirectTo, to.params);
            return false;
        } else if (to.externalUrl) {
            $window.open(to.externalUrl, '_blank');
            return false;
        } else if (to.data.requireLogin && !UserService.getPersistedUser()) {
            return $state.target('account_login', {state: to.name, stateParams: transition.params()});
        }

        $rootScope.productMeta = null;
        if (to.data && to.data.title) {
            $rootScope.setMetaTitle(to.data.title);
        }
        if (to.data && to.data.description) {
            $rootScope.setMetaDescription(to.data.description);
        } else {
            $rootScope.setMetaDescription(originalDescription);
        }
        $rootScope.stateLoading = true;
    });

    $rootScope.setMetaTitle = function (text) {
        $rootScope.title = text;
    };
    $rootScope.titleCompany = 'Vallen';
    switch (DOMAIN) {
        case 'boringsmith':
            $rootScope.titleCompany = 'Boring-Smith';
            break;
    }

    $rootScope.setMetaDescription = function (text) {
        $rootScope.description = text;
        $window.document.getElementsByName('description')[0].content = $rootScope.description;
    };

    $transitions.onError({}, function(transition) {
        $rootScope.stateLoading = false;
    });

    $state.defaultErrorHandler(function(error) {
        // Do not log transition superseded errors
        if (error.message !== 'The transition has been superseded by a different transition') {
            console.error(error.message);
        }
    });

    $transitions.onFinish({}, function(transition) {
        $rootScope.stateLoading = false;
    });

    $rootScope.$on('$stateNotFound', function () {
        $rootScope.stateLoading = false;
    });

    $rootScope.isArray = Array.isArray;

    var lastLocation = null;
    $transitions.onSuccess({}, function(transition) {
        $rootScope.stateLoading = false;
        $rootScope.refreshData = null;
        $rootScope.previousState = {
            state: transition.from(),
            params: transition.params('from')
        };

        FilterService.deleteFilter('search_query');

        //Remove all zoom containers on state change, Elevate Zoom doesn't like to clean up after its self.
        var elements = document.getElementsByClassName('zoomContainer');
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }

        $window.scrollTo(0, 0);

        $rootScope.hideSearch = ($rootScope.isMobile && $location.url() === '/');

        setTimeout(function () {
            var path = $location.path();
            var data = {
                page: $location.url(),
                url: $location.absUrl(),
                title: document.title ? document.title.replace('| Shop Vallen', '').trim() : 'Shop Vallen'
            };
            if (path.indexOf('?') !== -1) {
                data.search = path.substring(path.indexOf('?'), path.length);
            }
            if (lastLocation) {
                data.referrer = lastLocation;
            }

            AnalyticsService.sendPageView();

            lastLocation = $location.absUrl();
        }, 10);
    });
}]);

app.filter('br2nl', ['$sce', function ($sce) {
    return function (text) {
        return text ? text.replace(/<br>/g, '\n').replace(/<br\/>/g, '\n') : '';
    };
}]);

app.filter('orderStage', [
    function() {
        return function (text) {
            if (undefined != text) {
                var number = parseInt(text);
                switch (number) {
                    case 0:
                        return 'Entered';
                    case 1:
                        return 'Ordered';
                    case 2:
                        return 'Picked';
                    case 3:
                        return 'Shipped';
                    case 4:
                        return 'Invoiced';
                    case 5:
                        return 'Paid';
                    case 9:
                        return 'Cancelled';
                    default:
                        return text;
                }
            } else {
                return 'Ordered';
            }
        }    
    }
]);

app.filter('orderType', [
    function () {
        return function (text) {
            if (undefined != text) {
                switch (text.toLowerCase()) {
                    case 'o':
                    case 'so':
                        return 'Order';
                    case 'q':
                    case 'qu':
                        return 'Quote';
                    case 'b':
                        return 'Backorder';
                    case 'd':
                    case 'do':
                        return 'Direct';
                    case 'f':
                    case 'fo':
                        return 'Future';
                    case 's':
                    case 'st':
                        return 'Standing';
                    case 'k':
                    case 'bl':
                        return 'Blanket';
                    case 'c':
                    case 'cs':
                        return 'Counter Sale';
                    case 'cr':
                        return 'Correction';
                    case 'r':
                    case 'rm':
                        return 'Return';
                    default:
                        return text;
                }
            }
        }
    }
]);

app.filter('na', function () {
    return function (text) {
        return text ? text : 'N/A';
    };
});

app.filter('zeroPad', function () {
    return function(input,len){
        if (undefined === input) input = '';
        input = input.toString();
        if(input.length >= len) return input;
        else return("000000"+input).slice(-len);
    }
});

app.filter('orderByObject', function() {
    return function(items, field, reverse) {
        var filtered = [];
        angular.forEach(items, function(item) {
            filtered.push(item);
        });
        filtered.sort(function (a, b) {
            return (a[field] > b[field] ? 1 : -1);
        });
        if(reverse) filtered.reverse();
        return filtered;
    }
});

app.filter('orderByObjectKeys', function() {
    return function sortObject(obj) {
        if (!obj) {
            return obj;
        }
        return Object.keys(obj).sort().reduce(function (result, key) {
            result[key] = obj[key];
            return result;
        }, {});
    }
});

app.filter('partNumber', function () {
    return function(item, customer){
        if (customer && customer.use_cpn && (item.cpn || (item.webData && item.webData.cpn))) {
            return item.cpn ? item.cpn : item.webData.cpn;
        }
        return item.itemNumber ? item.itemNumber : item.erp;
    }
});

app.filter('phone', function () {
    return function(input){
        if (input) {
            var clean = input.replace(/[^\d]/g, "");
            var match = clean.match(/^(\d?)(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                match.shift();
                if (!match[0]) { match.shift(); }
                return match.join('-');
            }
        }
        return input;
    }
});

app.filter('shippingStatus', ['$sce', function ($sce) {
    return function (order) {
        if(order.header){
            if (order.header.trackingUrl) {
                return $sce.trustAsHtml('<a href="'+order.header.trackingUrl+'" target="_blank">'+order.header.proNumber+' <i class="fa fa-external-link text-primary"></i></a>');
            } else if (order.header.proNumber) {
                return order.header.proNumber;
            } else if (!order.header.orderStatus || order.header.orderStatus < 3) {
                return 'Preparing shipment';
            } else if (order.header.orderStatus <= 5) {
                return 'Shipped';
            }
        }
        return 'N/A';
    };
}]);

app.filter('trimText', function () {
    return function (text, limit) {
        limit = parseInt(limit, 10);
        if (!text || !limit || (text && text.length <= limit)) {
            return text;
        }
        return text.substr(0, limit) + ' …';
    };
});

app.filter('emailUser', function () {
    return function (email) {
        var x = email.split("@");
        if (x[0]) { email = x[0]; }
        return email;
    };
});

app.constant('BLACKLIST_FACETS', ['Competitor Part#', 'Trane Part#']);

app.filter('ordinal', function() {
    return function (number) {
        if (isNaN(number) || number < 1) {
            return number;
        } else {
            var lastDigit = number % 10;
            if (lastDigit === 1) {
                return number + 'st'
            } else if (lastDigit === 2) {
                return number + 'nd'
            } else if (lastDigit === 3) {
                return number + 'rd'
            } else if (lastDigit > 3) {
                return number + 'th'
            }
        }
    };
});
