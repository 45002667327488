(function () {
    'use strict'
}());

app.service('ScanToWebService', [
    '$http',
    '$q',
    'UserService',
    'API_URL',
    function ($http, $q, UserService, API_URL) {
        var base_url = API_URL + "/scan-to-web";

        this.getScans = function (params) {
            var defer = $q.defer();
            if(params === undefined) {
                params = {};
            }

            var queryString = Object.keys(params).map(function (key) {
                return key + '=' + encodeURIComponent(params[key])
            }).join('&');

            $http({
                url: base_url + '?' + queryString,
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };
        
        this.getScanById = function (id, status) {
            var defer = $q.defer();
            var url = base_url + "?id=" + id;
            if (status !== undefined) {
                url += '&status=' + status;
            }
            $http({
                url: url,
                headers: { "Authorization" : "Bearer {" + UserService.getToken() + "}" }
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };

        this.updateScan = function (scan) {
            var defer = $q.defer();
            var url = base_url + "/" + scan.id;
            
            scan.items.forEach( function (item) {
                if (item.hasOwnProperty('specifications')) {
                    item.specifications.forEach(function (specification) {
                        delete specification["$$hashKey"];
                    });
                }
            })
            
            $http({
                url: url,
                method: 'PUT',
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" },
                data: JSON.stringify(scan)
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };

        this.removeScan = function (scan) {
            var defer = $q.defer();
            var url = base_url + "/" + scan.id;
            $http({
                url: url,
                method: 'DELETE',
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then( function () {
                defer.resolve(true);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };

        this.archiveScan = function (scan) {
            var defer = $q.defer();
            var url = base_url + "/archive/" + scan.id;
            $http({
                url: url,
                method: 'PUT',
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then( function () {
                defer.resolve(true);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };

        // @todo come back later
        // this.patchReadState = function (scan) {
            // var url = base_url + "/read/" + scan.id;
            // $http({
                // url: url,
                // method: 'PATCH',
                // headers: {"Authorization": "Bearer {" + UserService.getToken() + "}" }
            // }).then( function () {
                // return true
            // });
        // };
    }
]);
