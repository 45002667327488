(function () {
    'use strict';
}());

app.service('InvoiceService', [
    '$rootScope',
    '$http',
    '$location',
    '$q',
    '$state',
    'API_URL',
    'UserService',
    function ($rootScope, $http, $location, $q, $state, API_URL, UserService) {
        var _getInvoicesBase = function (
            customerNumber,
            fromDate,
            toDate,
            rangeType,
            limit,
            offset,
            filter,
            url,
            collectionName
        ) {
            var deferral = $q.defer();

            offset = offset || 1;
            limit = limit || 50;

            url = API_URL + url;

            // todo: this code duplicates in many places, need to create a helper
            var params = [];

            if (undefined != customerNumber) {
                params.push('customerNumber=' + customerNumber);
            }

            if (undefined != fromDate) {
                params.push('from=' + fromDate);
            }

            if (undefined != toDate) {
                params.push('to=' + toDate);
            }

            if (undefined != limit) {
                params.push('limit=' + limit);
            }

            if (undefined != offset) {
                params.push('offset=' + offset);
            }

            if (undefined != filter) {
                params.push('filter=' + filter);
            }

            if (rangeType == 'dueDate') {
                params.push('type=aging');
            }

            var queryParams = params.join('&');

            url += '?' + queryParams;

            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then(function (response) {
                var data = response.data;
                var result = [];
                if (!data[collectionName].isArray) {
                    for (var i in data[collectionName]) {
                        var fullInvoiceInfo = data[collectionName][i];
                        var invoiceInfo = fullInvoiceInfo.invoice;
                        if (!invoiceInfo) { continue; }

                        if (collectionName == 'paid_invoices') {
                            invoiceInfo.lastTransactionDate
                                = fullInvoiceInfo.lastTransactionDate || fullInvoiceInfo.transactionDate;
                        } else {
                            invoiceInfo.lastTransactionDate = fullInvoiceInfo.lastTransactionDate;
                        }

                        invoiceInfo.ageDate = fullInvoiceInfo.ageDate;
                        invoiceInfo.invoiceDate = fullInvoiceInfo.invoiceDate;
                        result.push(invoiceInfo);
                    }
                }

                deferral.resolve(result);
            }, function () {
                deferral.reject();
            });

            return deferral.promise;
        };

        this.getPaidInvoices = function (customerNumber, fromDate, toDate, rangeType, limit, offset, filter) {
            return _getInvoicesBase(
                customerNumber,
                fromDate,
                toDate,
                rangeType,
                limit,
                offset,
                filter,
                '/invoices/paid',
                'paid_invoices'
            );
        };

        this.getOpenInvoices = function (customerNumber, fromDate, toDate, rangeType, limit, offset, filter) {
            return _getInvoicesBase(
                customerNumber,
                fromDate,
                toDate,
                rangeType,
                limit,
                offset,
                filter,
                '/invoices/open',
                'open_invoices'
            );
        };

        this.sendEmailInvoice = function (
            orderNumber,
            customerNumber,
            userEmail
        ) {

            var deferral = $q.defer();

            var url = API_URL + "/invoices/send_invoice_email";

            var params = [];

            if (undefined != orderNumber) {
                params.push('orderNumber=' + orderNumber);
            }

            if (undefined != customerNumber) {
                params.push('customerNumber=' + customerNumber);
            }

            if (undefined != userEmail) {
                params.push('userEmail=' + userEmail);
            }

            var queryParams = params.join('&');

            url += '?' + queryParams;

            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then(function (response) {
                deferral.resolve(response);
            }, function () {
                deferral.reject('Unable to find order.');
            });

            return deferral.promise;
        };
    }
]);
